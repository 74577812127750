import React, { useState, useEffect } from "react";
import Gallery from "react-photo-gallery";

import WallpaperCustomRenderer from "./WallpaperCustomRenderer";
import Lightbox from "../Lightbox";
import ContainerDimensions from "react-container-dimensions";

import { useSelector, useDispatch } from "react-redux";
import { openLightbox, setCurrentIndex } from "../../actions/lightbox";

const ImageGallery = ({ wallpapers }) => {
  const galleryData = useSelector((state) => state.gallery.data);

  const data = wallpapers ? wallpapers : galleryData;
  const dispatch = useDispatch();

  // useEffect(() => {}, []);

  return (
    <div className="image-gallery">
      {data && data.length > 0 && (
        <ContainerDimensions>
          {({ width, height }) => {
            let columns = 1;
            if (width > 500) columns = 2;
            if (width > 900) columns = 3;
            if (width > 1200) columns = 4;
            return (
              <Gallery
                photos={data}
                direction={"column"}
                columns={columns}
                renderImage={WallpaperCustomRenderer}
                margin={10}
                onClick={(event, { photo, index }) => {
                  dispatch(setCurrentIndex(index));
                  dispatch(openLightbox());
                }}
              />
            );
          }}
        </ContainerDimensions>
      )}
      <Lightbox />
    </div>
  );
};

export default ImageGallery;
