import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { FiSend, FiMeh, FiArrowLeft } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Spinner,
} from "reactstrap";

import { retrieveUser } from "../../services/api/user";
import { getCookie, isAuth } from "../../services/api/auth";
import FollowButton from "../Buttons/FollowButton";
import { addGalleryData, setGalleryData } from "../../actions/gallery";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../Avatar";
import CreatedTabContent from "./CreatedTabContent";
import FollowersModal from "./Followers";
import FollowingModal from "./Following";
import millify from "millify";

const ProfileComponent = ({ username }) => {
  const dispatch = useDispatch();

  const token = getCookie("token");
  const [activeTab, setActiveTab] = useState("1");

  const [values, setValues] = useState({
    user: {},
    followersCount: 0,
    followingCount: 0,
    wallpaperCount: 0,
    isFollowing: false,
    isFollower: false,
    error: "",
    loading: true,
  });
  const {
    user,
    followersCount,
    followingCount,
    wallpaperCount,
    isFollowing,
    isFollower,
    error,
    loading,
  } = values;

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const initProfileData = async (username) => {
    const userData = await retrieveUser(username, token);

    if (userData.error) {
      setValues({ ...values, error: userData.error, loading: false });
    } else {
      setValues({
        ...values,
        user: userData.user,
        followersCount: userData.followers,
        followingCount: userData.following,
        wallpaperCount: userData.wallpapers,
        isFollowing: userData.isFollowing,
        isFollower: userData.isFollower,
        error: "",
        loading: false,
      });
    }
  };

  useEffect(() => {
    initProfileData(username);
  }, [username]);

  const handleFollow = (value) => {
    if (value == true) {
      setValues({
        ...values,
        followersCount: followersCount + 1,
        // isFollowing: true,
      });
    } else {
      setValues({
        ...values,
        followersCount: followersCount - 1,
        // isFollowing: false,
      });
    }
  };

  const tabContent = () => {
    return (
      <React.Fragment>
        <Nav tabs className="align-self-center">
          <NavItem>
            <NavLink
              className={`${activeTab === "1" && "active"}`}
              onClick={() => {
                toggle("1");
              }}
            >
              Created
            </NavLink>
          </NavItem>
          {/* {isAuth() && user && user._id && isAuth()._id === user._id && (
            <NavItem>
              <NavLink
                className={`${activeTab === "2" && "active"}`}
                onClick={() => {
                  toggle("2");
                }}
              >
                Bookmarks
              </NavLink>
            </NavItem>
          )} */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="container-fluid p-0">
              <div className="row py-3">
                <Col sm="12">
                  {user && user.username && (
                    <CreatedTabContent username={username} key={username} />
                  )}
                </Col>
              </div>
            </div>
          </TabPane>
          {/* {isAuth() && user && user._id && isAuth()._id === user._id && (
            <TabPane tabId="2">
              <div className="row py-3">
                <Col sm="12">
                  <h4>Tab 2 Contents</h4>
                </Col>
              </div>
            </TabPane>
          )} */}
        </TabContent>
      </React.Fragment>
    );
  };

  const seoContent = () => {
    return (
      <Helmet>
        <title>
          {process.env.REACT_APP_APP_NAME}
          {" - " + (user.fullName ? user.fullName : "@" + user.username)}
        </title>

        {/* PRIMARY */}
        <meta
          name="title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + (user.fullName ? user.fullName : "@" + user.username)
          }`}
        />
        {user.bio && <meta name="description" content={user.bio} />}

        {/* Open Graph */}
        <meta
          property="og:title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + (user.fullName ? user.fullName : "@" + user.username)
          }`}
        />
        {user.bio && <meta property="og:description" content={user.bio} />}
        {user.avatar && (
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${user.avatar}`}
          />
        )}

        {/* Twitter */}
        {user.avatar && <meta property="twitter:card" content="summary" />}

        <meta
          property="twitter:title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + (user.fullName ? user.fullName : "@" + user.username)
          }`}
        />
        {user.bio && <meta property="twitter:description" content={user.bio} />}
        {user.avatar && (
          <meta
            property="twitter:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${user.avatar}`}
          />
        )}
      </Helmet>
    );
  };

  const pageContent = () => {
    return (
      <React.Fragment>
        {user && seoContent()}

        <div className="d-flex flex-wrap" key={user._id}>
          <div className="p-4">
            <Avatar
              // onClick={(e) => console.log(e.target.src)}
              width="256px"
              height="256px"
              // maxHeight='256px'
              src={user && user.avatar}
            />
          </div>
          <div className="p-4">
            <h1 className={`mb-3  ${user.fullName && "text-capitalize"}`}>
              {user && (user.fullName ? user.fullName : "@" + user.username)}
            </h1>
            <p className="mb-3">{user && user.bio}</p>
            {/* <p className="mb-3 font-weight-light">
              Travailing , Sports , Movies
            </p> */}

            <div className="mb-3 d-flex">
              {isAuth() && user && user._id && isAuth()._id === user._id ? (
                <React.Fragment>
                  <Link to="/settings">
                    <button className="btn btn-primary mr-3 text-nowrap">
                      <BiEditAlt color="#ffffff" size={24} />
                      <span className="ml-2">Edit Profile</span>
                    </button>
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FollowButton
                    following={isFollowing}
                    follower={isFollower}
                    handleChange={handleFollow}
                    userId={user._id}
                  />

                  {/* <button className="btn btn-secondary mr-3 text-nowrap">
                    <FiSend color="#ffffff" size={20} />{" "}
                    <span className="ml-2">Send Message</span>
                  </button> */}
                </React.Fragment>
              )}
            </div>

            <div className="mb-3 d-flex justify-content-between flex-wrap">
              <h5 className="mr-5">
                {/* <span className="text-muted">120K</span> Wallpapers */}
                <span className="text-muted">
                  {millify(wallpaperCount)}
                </span>{" "}
                Wallpapers
              </h5>
              <FollowersModal
                followersCount={followersCount}
                userId={user._id}
              />
              <FollowingModal
                followingCount={followingCount}
                userId={user._id}
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column mt-5">{tabContent()}</div>
      </React.Fragment>
    );
  };

  return (
    <div>
      {loading && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <Spinner size="xl">Loading Profile Data...</Spinner>
        </div>
      )}

      {error ? (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="d-block text-center">
              <FiMeh size={150} color="#ffffff" />
              <h1 className="mt-5 display-5">{error}</h1>
              <Link to="/">
                <a className="btn btn-secondary mt-5">
                  {/* <FiArrowLeft size={24} color="#ffffff" />  */}
                  Go Back to Homepage
                </a>
              </Link>
            </div>
          </div>
        </React.Fragment>
      ) : (
        user && user._id && pageContent()
      )}
    </div>
  );
};

export default ProfileComponent;
