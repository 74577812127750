import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import { getCookie, isAuth, updateAuth } from "../../services/api/auth";
import { updatePassword } from "../../services/api/user";

const SecuritySettings = () => {
  const token = getCookie("token");
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    loading: false,
    error: "",
    success: false,
  });
  const { oldPassword, newPassword, confirmPassword, loading, error, success } =
    values;

  useEffect(() => {
    const localData = isAuth();

    setValues({
      ...values,
      oldPassword: localData.oldPassword,
      newPassword: localData.newPassword,
      confirmPassword: localData.confirmPassword,

      loading: false,
      error: "",
      success: false,
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true, success: false, error: "" });

    if (!oldPassword) {
      setValues({ ...values, error: "Old Password cannot be empty" });
      return;
    } else if (!newPassword) {
      setValues({ ...values, error: "New Password cannot be empty" });
      return;
    } else if (newPassword.length < 6) {
      setValues({
        ...values,
        error: "New Password must be minimum 6 characters",
      });
      return;
    } else if (!confirmPassword) {
      setValues({ ...values, error: "Confirm Password cannot be empty" });
      return;
    } else if (newPassword != confirmPassword) {
      setValues({ ...values, error: "New and Confirm Password do not match" });
      return;
    } else {
      const userData = {
        oldPassword,
        newPassword,
        // confirmPassword,
      };

      updatePassword(userData, token).then((data) => {
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
            success: false,
            loading: false,
          });
        } else {
          setValues({
            ...values,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            loading: false,
            error: "",
            success: true,
          });
        }
      });
    }
  };

  const formContent = () => {
    return (
      <React.Fragment>
        <Form>
          {error && <div className="mt-3 alert alert-danger">{error}</div>}
          {success && (
            <div className="mt-3 alert alert-success">
              Your Password is Updated Successfully
            </div>
          )}

          <FormGroup>
            <Label for="oldPassword">Old Password</Label>
            <Input
              type="text"
              name="oldPassword"
              id="oldPassword"
              placeholder="Please Enter Old Password"
              value={oldPassword}
              onChange={(e) =>
                setValues({ ...values, oldPassword: e.target.value })
              }
            />
          </FormGroup>
          <hr />
          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <Input
              type="text"
              name="newPassword"
              id="newPassword"
              placeholder="Please Enter New Password, Max 32 Characters"
              value={newPassword}
              onChange={(e) =>
                setValues({ ...values, newPassword: e.target.value })
              }
              maxLength={32}
            />
          </FormGroup>

          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Input
              type="text"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Please Enter New Password Again"
              value={confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
              maxLength={32}
            />
          </FormGroup>

          <Button
            className={`btn btn-secondary`}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                // style={{ width: "0.7rem", height: "0.7rem" }}
                type="grow"
                color="light"
                size="sm"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 pl-0">
            <h4>Security</h4>
            <p>Update your password</p>
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">{formContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SecuritySettings;
