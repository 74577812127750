import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Linkify from "linkifyjs/react";
import UserCard from "../../UserCard";
import FollowButton from "../../Buttons/FollowButton";
import { linkifyOptions } from "../../../utils/linkify";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { singleNotificationRead } from "../../../actions/notification";

const NotificationList = () => {
  const dispatch = useDispatch();
  const { data: notifications } = useSelector((state) => state.notification);

  return (
    <React.Fragment>
      {notifications.map((notification, index) => {
        const userCardProps = {
          username: notification.sender.username,
          avatar: notification.sender.avatar,
          subTextDark: true,
          // token: token,
          date: notification.date,
          style: { minHeight: "7rem", padding: "1rem 1.5rem" },
          onClick: () => dispatch(singleNotificationRead(notification._id)),
          className:
            notification.read == true ? "bg-dark-secondary" : "bg-dark-primary",
        };
        let userCardChild = null;

        switch (notification.type) {
          case "follow": {
            // userCardProps.subText = notification.isFollowing
            //   ? "followed you back"
            //   : "started following you";
            userCardProps.subText = "started following you";
            userCardProps.href = `/profile/${userCardProps.username}`;
            // don't show follow button if we (receiver) already follow him isFollowing
            // it is a followBack
            // userCardChild = notification.isFollowing ? null : (
            //   <FollowButton
            //     following={notification.isFollowing}
            //     follower={notification.isFollower}
            //     handleChange={() => console.log("Handle Follow")}
            //     userId={notification.sender._id}
            //   />
            // );

            break;
          }
          case "like": {
            userCardProps.subText = `liked your ${notification.data.type}.`;
            userCardProps.href = `/wallpaper/${notification.data.id}`;
            userCardChild = (
              <img
                src={`${process.env.REACT_APP_S3_BUCKET}/${notification.data.src}`}
                className="br-2"
                style={{
                  display: "flex",
                  height: "40px",
                }}
                alt="liked post"
              />
            );
            break;
          }

          case "comment": {
            userCardProps.subText = (
              <Linkify
                options={linkifyOptions}
              >{`commented: ${notification.data.message}`}</Linkify>
            );
            userCardProps.href = `/wallpaper/${notification.data.id}?comment=${notification.data.comment}`;

            userCardChild = (
              <img
                src={`${process.env.REACT_APP_S3_BUCKET}/${notification.data.src}`}
                className="br-2"
                style={{
                  display: "flex",
                  height: "40px",
                }}
                alt="liked post"
              />
            );

            break;
          }

          case "comment-reply": {
            userCardProps.subText = (
              <Linkify
                options={linkifyOptions}
              >{`replied: ${notification.data.message}`}</Linkify>
            );
            userCardProps.href = `/wallpaper/${notification.data.id}?comment-reply=${notification.data.commentReply}`;

            userCardChild = (
              <img
                src={`${process.env.REACT_APP_S3_BUCKET}/${notification.data.src}`}
                className="br-2"
                style={{
                  display: "flex",
                  height: "40px",
                }}
                alt="liked post"
              />
            );

            break;
          }

          case "mention": {
            userCardProps.subText = (
              <Linkify
                options={linkifyOptions}
              >{`mentioned you in a comment: ${notification.data.message}`}</Linkify>
            );
            userCardProps.href = `/wallpaper/${notification.data.id}?comment-reply=${notification.data.commentReply}`;
            // userCardChild = notification.isFollowing ? null : (
            //   <FollowButton
            //     following={notification.isFollowing}
            //     follower={notification.isFollower}
            //     handleChange={() => console.log("Handle Follow")}
            //     userId={notification.sender._id}
            //   />
            // );

            break;
          }

          default: {
            userCardProps.subText = "";
            // userCardChild = (
            //   <Link to={`/post/${notification.data.postId}`}>
            //     <img
            //       src={notification.data.image}
            //       style={{
            //         display: "flex",
            //         filter: notification.data.filter,
            //       }}
            //       onClick={() =>
            //         setShowNotifications && setShowNotifications(false)
            //       }
            //       alt="post commented on"
            //     />
            //   </Link>
            // );
          }
        }

        return (
          <li key={index}>
            <UserCard {...userCardProps}>
              {userCardChild && userCardChild}
            </UserCard>
            {notifications.length - 1 > index && (
              <DropdownItem divider className="m-0" />
            )}
          </li>
        );
      })}
    </React.Fragment>
  );
};

export default NotificationList;
