const initialPreferences = {
  isOpen: false,
  editing: false,
  currentIndex: null,
  currentWallpaper: null,
  comments: {
    data: [],
    count: 0,
    hasMore: true,
  },
  mediaReady: false,
};

// CONFIG REDUCER
const lightbox = (state = initialPreferences, action) => {
  /* -------------------------------------------------------------------------- */
  /*                                  LIGHTBOX                                  */
  /* -------------------------------------------------------------------------- */

  switch (action.type) {
    case "OPEN_LIGHTBOX":
      return { ...state, isOpen: true };
    case "CLOSE_LIGHTBOX":
      return {
        ...state,
        isOpen: false,
        currentIndex: null,
        currentWallpaper: null,
        comments: {
          data: [],
          count: 0,
          hasMore: true,
        },
      };
    case "TOGGLE_LIGHTBOX":
      // return { ...state, isOpen: !state.isOpen };
      if (state.isOpen) {
        return {
          ...state,
          isOpen: false,
          currentIndex: null,
          currentWallpaper: null,
          comments: {
            data: [],
            count: 0,
            hasMore: true,
          },
        };
      } else {
        return { ...state, isOpen: true };
      }
    case "SET_EDITING_LIGHTBOX":
      return { ...state, editing: action.payload.value };
    case "SET_CURRENT_INDEX_LIGHTBOX":
      return {
        ...state,
        currentIndex: action.payload.index,
        mediaReady: false,
      };

    /* -------------------------------------------------------------------------- */
    /*                                  WALLPAPER                                 */
    /* -------------------------------------------------------------------------- */

    case "SET_WALLPAPER_LIGHTBOX":
      return {
        ...state,
        currentWallpaper: { ...state.currentWallpaper, ...action.payload.data },
        editing: false,
      };
    case "SET_MEDIA_READY_LIGHTBOX":
      return { ...state, mediaReady: action.payload.value };

    /* -------------------------------------------------------------------------- */
    /*                                  COMMENTS                                  */
    /* -------------------------------------------------------------------------- */

    case "SET_COMMENTS":
      return {
        ...state,
        comments: {
          data: action.payload.data.data,
          count: action.payload.data.count,
          hasMore: action.payload.data.data.length < 10 ? false : true,
        },
      };

    case "LOAD_MORE_COMMENTS":
      return {
        ...state,
        comments: {
          data: [...state.comments.data, ...action.payload.data.data],
          count: action.payload.data.count,
          hasMore:
            state.comments.data.length + action.payload.data.data.length >=
              action.payload.data.count || action.payload.data.data.length < 10
              ? false
              : true,
        },
      };

    case "CREATE_COMMENT":
      return {
        ...state,
        comments: state.comments
          ? {
              ...state.comments,
              data: [...state.comments.data, action.payload.data],
              count: state.comments.count + 1,
            }
          : {
              data: [action.payload.data],
              count: 0 + 1,
            },
      };
    case "UPDATE_COMMENT":
      let updatedComments = state.comments.data;
      let updatedCommentIndex = updatedComments.findIndex(
        (c) => c._id === action.payload.data._id
      );
      updatedComments[updatedCommentIndex].isLiked = false;
      updatedComments[updatedCommentIndex].edited = true;
      updatedComments[updatedCommentIndex].content =
        action.payload.data.content;

      return {
        ...state,
        comments: {
          ...state.comments,
          data: updatedComments,
        },
      };
    case "REMOVE_COMMENT":
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (item) => item._id !== action.payload.id
          ),
          count: state.comments.count - 1,
        },
      };

    /* -------------------------------------------------------------------------- */
    /*                                COMMENT REPLY                               */
    /* -------------------------------------------------------------------------- */

    default:
      return state;
  }
};

export default lightbox;
