import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getCookie, isAuth } from "../../../../../services/api/auth";
import {
  editWallpaper,
  setEditing,
  deleteWallpaper,
  closeLightbox,
} from "../../../../../actions/lightbox";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  FiMoreVertical,
  FiEdit,
  FiShare2,
  FiTrash2,
  FiFlag,
  FiCheck,
  FiX,
} from "react-icons/fi";
import Avatar from "../../../../Avatar";
import { openReportModal } from "../../../../../actions/report";

const LightboxDetailsHeader = () => {
  const dispatch = useDispatch();
  const { currentWallpaper: wallpaper } = useSelector(
    (state) => state.lightbox
  );

  const menu = () => {
    return (
      <React.Fragment>
        <UncontrolledDropdown className="ml-2" inNavbar={false}>
          <DropdownToggle tag="a" caret={false}>
            <FiMoreVertical
              className="my-auto h-100"
              color="#7d7d7d"
              size={20}
            />
          </DropdownToggle>
          <DropdownMenu right positionFixed={false}>
            {isAuth() && isAuth()._id == wallpaper.createdBy._id && (
              <DropdownItem onClick={() => dispatch(setEditing(true))}>
                <FiEdit className="mr-2" color="#7d7d7d" size={20} /> Edit
              </DropdownItem>
            )}

            {isAuth() && isAuth()._id == wallpaper.createdBy._id ? null : (
              <DropdownItem
                onClick={() =>
                  dispatch(
                    openReportModal({
                      contentType: "wallpaper",
                      contentId: wallpaper._id,
                    })
                  )
                }
              >
                <FiFlag className="mr-2" color="#7d7d7d" size={20} /> Report
              </DropdownItem>
            )}

            {isAuth() && isAuth()._id == wallpaper.createdBy._id && (
              <React.Fragment>
                <DropdownItem divider />
                <DropdownItem
                  className="text-danger"
                  onClick={() => dispatch(deleteWallpaper(wallpaper._id))}
                >
                  <FiTrash2 className="mr-2" color="" size={20} /> Delete
                </DropdownItem>
              </React.Fragment>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </React.Fragment>
    );
  };

  const navigate = useNavigate();
  const profileClicked = () => {
    if (wallpaper && wallpaper.createdBy && wallpaper.createdBy.username) {
      // push
      navigate(`/profile/${wallpaper.createdBy.username}`);

      setTimeout(() => {
        // close modal
        dispatch(closeLightbox());
      }, 0);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-row border-bottom p-2">
      <Avatar
        onClick={profileClicked}
        width="auto"
        height="40px"
        src={wallpaper && wallpaper.createdBy && wallpaper.createdBy.avatar}
        size="sm"
      />

      {/* {wallpaper && wallpaper.createdBy && wallpaper.createdBy.username && (
        <Link to={`/profile/${wallpaper.createdBy.username}`}>
          <a className="ml-3">{wallpaper.createdBy.username}</a>
        </Link>
      )} */}

      <div className="ml-3" style={{ fontSize: "1.1em" }}>
        {wallpaper && wallpaper.createdBy ? (
          <a
            onClick={profileClicked}
            // href={`/profile/${wallpaper.createdBy.username}`}
          >
            {wallpaper.createdBy.fullName
              ? wallpaper.createdBy.fullName
              : wallpaper.createdBy.username && wallpaper.createdBy.username}
          </a>
        ) : null}
      </div>

      <span className="ml-auto">
        {wallpaper && wallpaper.createdBy && menu()}
      </span>
    </div>
  );
};

export default LightboxDetailsHeader;
