import React from "react";
import Private from "../components/Auth/Private";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import { Card, Button, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import SEO from "../components/Misc/SEO";

const terms = () => {
  const pageContent = () => {
    return (
      <React.Fragment>
        <h1 className="mb-4">Terms and Conditions</h1>

        <br />
        <h4 className="">1. Terms</h4>

        <p>
          By accessing this Website, accessible from{" "}
          {process.env.REACT_APP_APP_DOMAIN}, you are agreeing to be bound by
          these Website Terms and Conditions of Use and agree that you are
          responsible for the agreement with any applicable local laws. If you
          disagree with any of these terms, you are prohibited from accessing
          this site. The materials contained in this Website are protected by
          copyright and trade mark law.
        </p>

        <br />
        <h4 className="">2. Use License</h4>

        <p>
          Permission is granted to download copy of the materials on{" "}
          {process.env.REACT_APP_APP_NAME}'s Website. This is the grant of a
          license, not a transfer of title, and under this license you may not:
        </p>

        <ul>
          <li>
            attempt to reverse engineer any software contained on{" "}
            {process.env.REACT_APP_APP_NAME}'s Website;
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transferring the materials to/from another person or "mirror" the
            materials on {process.env.REACT_APP_APP_NAME}/any other platform,
            resulting in copyright infringement.
          </li>
        </ul>

        <p>
          This will let {process.env.REACT_APP_APP_NAME} to terminate upon
          violations of any of these restrictions. Upon termination, your
          viewing right will also be terminated and you should destroy any
          downloaded materials in your possession whether it is printed or
          electronic format.
        </p>

        <br />
        <h4 className="">3. Disclaimer</h4>

        <p>
          All the materials on {process.env.REACT_APP_APP_NAME}’s Website are
          provided "as is".
          {process.env.REACT_APP_APP_NAME} makes no warranties, may it be
          expressed or implied, therefore negates all other warranties.
          Furthermore, {process.env.REACT_APP_APP_NAME} does not make any
          representations concerning the accuracy or reliability of the use of
          the materials on its Website or otherwise relating to such materials
          or any sites linked to this Website.
        </p>

        <br />
        <h4 className="">4. Limitations</h4>

        <p>
          {process.env.REACT_APP_APP_NAME} or its suppliers will not be hold
          accountable for any damages that will arise with the use or inability
          to use the materials on {process.env.REACT_APP_APP_NAME}’s Website,
          even if {process.env.REACT_APP_APP_NAME} or an authorize
          representative of this Website has been notified, orally or written,
          of the possibility of such damage. Some jurisdiction does not allow
          limitations on implied warranties or limitations of liability for
          incidental damages, these limitations may not apply to you.
        </p>

        <br />
        <h4 className="">5. Revisions and Errata</h4>

        <p>
          The materials appearing on {process.env.REACT_APP_APP_NAME}’s Website
          may include technical, typographical, or photographic errors.{" "}
          {process.env.REACT_APP_APP_NAME} will not promise that any of the
          materials in this Website are accurate, complete, or current.{" "}
          {process.env.REACT_APP_APP_NAME} may change the materials contained on
          its Website at any time without notice.{" "}
          {process.env.REACT_APP_APP_NAME} does not make any commitment to
          update the materials.
        </p>

        <br />
        <h4 className="">6. Links</h4>

        <p>
          {process.env.REACT_APP_APP_NAME} has not reviewed all of the sites
          linked to its Website and is not responsible for the contents of any
          such linked site. The presence of any link does not imply endorsement
          by {process.env.REACT_APP_APP_NAME} of the site. The use of any linked
          website is at the user’s own risk.
        </p>

        <br />
        <h4 className="">7. Site Terms of Use Modifications</h4>

        <p>
          {process.env.REACT_APP_APP_NAME} may revise these Terms of Use for its
          Website at any time without prior notice. By using this Website, you
          are agreeing to be bound by the current version of these Terms and
          Conditions of Use.
        </p>

        <br />
        <h4 className="">8. Your Privacy</h4>

        <p>
          Please read our <Link to="/privacy">Privacy Policy</Link>.
        </p>

        <br />
        <h4 className="">9. Governing Law</h4>

        <p>
          Any claim related to {process.env.REACT_APP_APP_NAME}'s Website shall
          be governed by the laws of in without regards to its conflict of law
          provisions.
        </p>
      </React.Fragment>
    );
  };

  return (
    <Layout>
      <SEO title="Terms" />
      <CustomScrollbar page={true}>
        <Card body className="p-5">
          {/* <CardTitle tag="h5">Special Title Treatment</CardTitle>
        <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        <Button>Go somewhere</Button> */}
          {pageContent()}
        </Card>
      </CustomScrollbar>
    </Layout>
  );
};

export default terms;
