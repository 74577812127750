import React, { useState, useEffect } from "react";
import { getCookie, isAuth } from "../../services/api/auth";
import { followUser } from "../../services/api/follow";
import { BiUserPlus, BiUserX } from "react-icons/bi";
import { useDispatch } from "react-redux";
import {
  decreaseFollowingCount,
  increaseFollowingCount,
} from "../../actions/user";

const FollowButton = ({ following, follower, handleChange, userId }) => {
  const token = getCookie("token");
  const [isFollowing, setIsFollowing] = useState(following);
  const [isFollower, setIsFollower] = useState(follower);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsFollower(follower);
  }, [follower]);

  useEffect(() => {
    setIsFollowing(following);
  }, [following]);

  const handleFollow = async () => {
    await followUser(userId, token).then((data) => {
      if (data.error) {
        console.log("Error = \n", data.error);
      }

      if (data.success == true) {
        if (data.operation == "unfollow") {
          dispatch(decreaseFollowingCount());
          setIsFollowing(false);
          handleChange(false);
        } else if (data.operation == "follow") {
          dispatch(increaseFollowingCount());
          setIsFollowing(true);
          handleChange(true);
        }
      }
    });
  };

  return (
    <React.Fragment>
      {isFollowing ? (
        <button
          className="btn btn-secondary mr-3 text-nowrap"
          onClick={handleFollow}
        >
          <BiUserX color="#ffffff" size={24} />
          <span className="ml-2">UnFollow</span>
        </button>
      ) : isFollower ? (
        <button
          className="btn btn-primary mr-3 text-nowrap"
          onClick={handleFollow}
        >
          <BiUserPlus color="#ffffff" size={24} />
          <span className="ml-2">Follow Back</span>
        </button>
      ) : (
        <button
          className="btn btn-primary mr-3 text-nowrap"
          onClick={handleFollow}
        >
          <BiUserPlus color="#ffffff" size={24} />
          <span className="ml-2">Follow</span>
        </button>
      )}
    </React.Fragment>
  );
};

export default FollowButton;
