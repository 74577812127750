import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { setActiveTab, setTimeframe } from "../../actions/gallery";

const GalleryHeader = ({
  scrollToTop,
  hot = false,
  latest = false,
  timeline = false,
}) => {
  const dispatch = useDispatch();
  const { activeTab, timeframe } = useSelector((state) => state.gallery);

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      dispatch(setActiveTab(tab));
    }
  };

  const pageContent = () => {
    return (
      <React.Fragment>
        <Nav tabs className='custom-top-nav mt-4'>
          {hot && (
            <NavItem>
              <NavLink
                className={`${activeTab == "hot" && "active"}`}
                onClick={() => {
                  changeTab("hot");
                  scrollToTop();
                }}
              >
                <h5>Hot</h5>
              </NavLink>
            </NavItem>
          )}

          {latest && (
            <NavItem>
              <NavLink
                className={`${activeTab == "latest" && "active"}`}
                onClick={() => {
                  changeTab("latest");
                  scrollToTop();
                }}
              >
                <h5>Latest</h5>
              </NavLink>
            </NavItem>
          )}

          {timeline && (
            <NavItem>
              <NavLink
                className={`${activeTab == "timeline" && "active"}`}
                onClick={() => {
                  changeTab("timeline");
                  scrollToTop();
                }}
              >
                <h5>Timeline</h5>
              </NavLink>
            </NavItem>
          )}

          {activeTab === "hot" && (
            <NavItem className='ml-auto'>
              <UncontrolledDropdown setActiveFromChild>
                <DropdownToggle
                  tag='a'
                  className='nav-link'
                  caret
                  style={{ textTransform: "capitalize" }}
                >
                  {timeframe}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => dispatch(setTimeframe("daily"))}
                    active={timeframe === "daily"}
                  >
                    Daily
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => dispatch(setTimeframe("weekly"))}
                    active={timeframe === "weekly"}
                  >
                    Weekly
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => dispatch(setTimeframe("monthly"))}
                    active={timeframe === "monthly"}
                  >
                    Monthly
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => dispatch(setTimeframe("all"))}
                    active={timeframe === "all"}
                  >
                    All
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          )}

          {/* <NavItem>View</NavItem> */}
        </Nav>
      </React.Fragment>
    );
  };

  return <React.Fragment>{pageContent()}</React.Fragment>;
};

export default GalleryHeader;
