import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import { getCookie, isAuth, updateAuth } from "../../services/api/auth";
import { updateUser } from "../../services/api/user";
import Avatar from "../Avatar";
import AvatarEdit from "../AvatarEdit";

const GeneralSettings = () => {
  const token = getCookie("token");
  const [values, setValues] = useState({
    email: "", // never updated
    fullName: "",
    username: "",
    bio: "",
    website: "",
    loading: false,
    error: "",
    success: false,
  });
  const { email, fullName, username, bio, website, loading, error, success } =
    values;

  useEffect(() => {
    const localData = isAuth();

    setValues({
      ...values,
      email: localData.email,
      fullName: localData.fullName,
      username: localData.username,
      bio: localData.bio,
      website: localData.website,
      loading: false,
      error: "",
      success: false,
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true, success: false, error: "" });

    if (!username) {
      setValues({ ...values, error: "Username cannot be empty" });
      return;
    }

    const userData = {
      fullName,
      username,
      bio,
      website,
    };

    updateUser(userData, token).then((data) => {
      if (data.error) {
        setValues({
          ...values,
          error: data.error,
          success: false,
          loading: false,
        });
      } else {
        updateAuth(data, () => {
          setValues({
            ...values,
            fullName: data.fullName,
            username: data.username,
            bio: data.bio,
            website: data.website,
            loading: false,
            error: "",
            success: true,
          });
        });
      }
    });
  };

  const formContent = () => {
    return (
      <React.Fragment>
        <AvatarEdit />

        <hr />
        <Form>
          {error && <div className="mt-3 alert alert-danger">{error}</div>}
          {success && (
            <div className="mt-3 alert alert-success">
              Your Profile is Updated Successfully
            </div>
          )}

          {/* <FormGroup className="p-2 br-1 bg-warning text-black">
            <Label className="m-0" for="email">
              Email: {email}
            </Label>
          </FormGroup> */}

          <FormGroup>
            <Label for="email" className="d-flex justify-content-between w-100">
              <span> Email </span>
              <span>(Can't be edited)</span>
            </Label>
            <Input type="text" name="email" id="email" value={email} disabled />
          </FormGroup>

          <FormGroup>
            <Label for="fullName">Full Name</Label>
            <Input
              type="text"
              name="fullName"
              id="fullName"
              placeholder="Please Enter Full Name"
              value={fullName}
              onChange={(e) =>
                setValues({ ...values, fullName: e.target.value })
              }
              maxLength={32}
            />
          </FormGroup>

          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              name="username"
              id="username"
              placeholder="Please Enter Username"
              value={username}
              onChange={(e) =>
                setValues({ ...values, username: e.target.value })
              }
              maxLength={32}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bio">Bio</Label>
            {/* <Input type="textarea" name="bio" id="bio" rows="5" /> */}
            <textarea
              className="d-block w-100"
              style={{ padding: ".75rem .75rem" }}
              name="bio"
              id="bio"
              rows="5"
              placeholder="I am an artist ........"
              value={bio}
              onChange={(e) => setValues({ ...values, bio: e.target.value })}
              maxLength={130}
            ></textarea>
          </FormGroup>
          <FormGroup>
            <Label for="website">Website</Label>
            <Input
              type="text"
              name="website"
              id="website"
              placeholder="Please Enter Website URL"
              value={website}
              onChange={(e) =>
                setValues({ ...values, website: e.target.value })
              }
              maxLength={65}
            />
          </FormGroup>

          <Button
            className={`btn btn-secondary`}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                // style={{ width: "0.7rem", height: "0.7rem" }}
                type="grow"
                color="light"
                size="sm"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 pl-0">
            <h4>General</h4>
            <p>Update your profile details</p>
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">{formContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralSettings;
