import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ModalUrlHandler = () => {
  const location = useLocation();
  const { isOpen, currentWallpaper } = useSelector((state) => state.lightbox);
  const [initURL, setInitURL] = useState("");

  useEffect(() => {
    if (!initURL) setInitURL(window.location.href);

    if (isOpen && currentWallpaper) {
      let newURL =
        window.location.origin + `/wallpaper/${currentWallpaper._id}`;
      window.history.pushState(null, "Wallpaper", newURL);
    } else {
      console.log(initURL);
      window.history.pushState(null, "", initURL);
      setInitURL(""); //Reset
    }
  }, [currentWallpaper]);

  useEffect(() => {
    // console.log(location.pathname);
    setInitURL(location.pathname);
  }, [location]);

  return null;
};

export default ModalUrlHandler;
