import { retrieveCategories } from "../services/api/category";
import { retrieveHotFeed, retrieveLatestFeed } from "../services/api/feed";

export const loadCategories = () => async (dispatch) => {
  const req = await retrieveCategories().then((data) => {
    if (!data) throw Error("API Server didn't respond");

    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  let finalData = req
    .map((category, index) => {
      let c = {};
      c.label = category.name;
      c.value = category._id;
      c.slug = category.slug;
      c.src = category.image;
      c.latest = {
        data: [],
        hasMore: true,
      };
      c.hot = {
        daily: {
          data: [],
          count: 0,
          hasMore: true,
          initialized: false,
        },
        weekly: {
          data: [],
          count: 0,
          hasMore: true,
          initialized: false,
        },
        monthly: {
          data: [],
          count: 0,
          hasMore: true,
          initialized: false,
        },
        all: {
          data: [],
          count: 0,
          hasMore: true,
          initialized: false,
        },
      };
      return c;
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return dispatch({
    type: "LOAD_CATEGORIES",
    payload: finalData,
  });
};

export const loadHotCategoryFeed =
  (offset, timeframe, categoryId) => async (dispatch) => {
    const wallpapers = await retrieveHotFeed(
      offset,
      timeframe,
      categoryId
    ).then((data) => {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    });

    if (offset > 0) {
      return dispatch({
        type: "LOAD_HOT_CATEGORY_FEED",
        payload: {
          timeframe: timeframe,
          data: wallpapers.data,
          count: wallpapers.count,
          id: categoryId,
        },
      });
    } else {
      return dispatch({
        type: "SET_HOT_CATEGORY_FEED",
        payload: {
          timeframe: timeframe,
          data: wallpapers.data,
          count: wallpapers.count,
          id: categoryId,
        },
      });
    }
  };

export const loadLatestCategoryFeed =
  (offset, categoryId) => async (dispatch) => {
    const wallpapers = await retrieveLatestFeed(offset, categoryId).then(
      (data) => {
        if (data.error) {
          throw data.error;
        } else {
          return data;
        }
      }
    );

    if (offset > 0) {
      return dispatch({
        type: "LOAD_LATEST_CATEGORY_FEED",
        payload: {
          data: wallpapers.data,
          count: wallpapers.count,
          id: categoryId,
        },
      });
    } else {
      return dispatch({
        type: "SET_LATEST_CATEGORY_FEED",
        payload: {
          data: wallpapers.data,
          count: wallpapers.count,
          id: categoryId,
        },
      });
    }
  };

// export const setCurrectCategory = (category) => async (dispatch) => {
//   return dispatch({
//     type: "SET_CURRENT_CATEGORY",
//     payload: category,
//   });
// };
