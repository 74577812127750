import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import FeedGallery from "../components/Feed";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import GalleryHeader from "../components/ImageGallery/GalleryHeader";
import SEO from "../components/Misc/SEO";

const Index = () => {
  const [scrollToTop, setScrollToTop] = useState(() => () => {});

  return (
    <Layout className="" innerClassName="d-flex flex-column">
      <SEO title="Home" />
      <div
        className="container-fluid px-sm-5 px-3 pb-4"
        // style={{ maxWidth: "1200px" }}
      >
        {/* {pageContent()} */}
        <GalleryHeader scrollToTop={scrollToTop} hot latest timeline />
      </div>
      <CustomScrollbar page={true} setScrollToTop={setScrollToTop} className="">
        <FeedGallery />
      </CustomScrollbar>
    </Layout>
  );
};

export default Index;
