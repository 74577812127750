import React from "react";
import CategoriesList from "../components/Category/CategoriesList";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import SEO from "../components/Misc/SEO";

const categories = () => {
  return (
    <Layout>
      <SEO title="Categories" />
      <div className="inner-content w-100">
        <CustomScrollbar page={true}>
          <h1 className="ml-4" style={{}}>
            Categories
          </h1>
          <CategoriesList />
        </CustomScrollbar>
      </div>
    </Layout>
  );
};

export default categories;
