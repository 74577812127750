import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeReportModal, toggleReportModal } from "../../actions/report";
import { createReport } from "../../services/api/report";
import { getCookie } from "../../services/api/auth";
import { FiThumbsUp } from "react-icons/fi";

const reasons = [
  {
    name: "Sexual content",
    value: "sexual-content",
  },
  {
    name: "Violent or repulsive content",
    value: "violence",
  },
  {
    name: "Hateful or abusive content",
    value: "hateful-abusive",
  },
  {
    name: "Harassment or bullying",
    value: "harassment-bullying",
  },
  {
    name: "Harmful or dangerous acts",
    value: "harmful-dangerous",
  },
  {
    name: "Child abuse",
    value: "child-abuse",
  },
  {
    name: "Promotes terrorism",
    value: "terrorism",
  },
  {
    name: "Spam or misleading",
    value: "spam",
  },
  {
    name: "Infringes my rights",
    value: "rights",
  },
  {
    name: "Wrong Device",
    value: "wrong-device",
  },
  {
    name: "Other",
    value: "other",
  },
];

const ReportModal = () => {
  const token = getCookie("token");
  const { isOpen, contentType, contentId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    reason: "",
    description: "",
    error: "",
    message: "",
  });
  const { reason, description, error, message } = values;

  // clean
  useEffect(() => {
    if (!isOpen) {
      // fade effect workaround
      setTimeout(() => {
        setValues({
          ...values,
          reason: "",
          description: "",
          error: "",
          message: "",
        });
      }, 1000);
    }
  }, [isOpen]);

  const changeReason = (e) => {
    if (e.target.value !== "other") {
      setValues({ ...values, reason: e.target.value, description: "" });
    } else {
      setValues({ ...values, reason: e.target.value });
    }
  };

  const changeDescription = (e) => {
    setValues({ ...values, description: e.target.value });
  };

  const submitReport = async (e) => {
    // e.preventDefault();

    if (!contentType) {
      setValues({
        ...values,
        error: "Some Internal Error, please contact devs",
      });
      console.log("Content Type is Required");
      return;
    }

    if (!contentId) {
      setValues({
        ...values,
        error: "Some Internal Error, please contact devs",
      });
      console.log("Content ID is Required");
      return;
    }

    if (!reason) {
      setValues({ ...values, error: "Reason is Required" });
      console.log("Reason is Required");
      return;
    }

    if (reason === "other" && !description) {
      setValues({ ...values, error: "Description is Required" });
      console.log("Description is Required");
      return;
    }

    const reportData = {
      contentType: contentType,
      contentId: contentId,
      reason: reason,
      description: description,
    };

    await createReport(reportData, token).then((data) => {
      if (data.error) {
        console.log("Error = ", data.error);
        setValues({ ...values, error: data.error });
      } else {
        setValues({ ...values, message: data.message });
      }
    });
  };

  const showError = () => (
    <div
      className="alert alert-danger m-0"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showMessage = () => (
    <h5 className="m-5" style={{ display: message ? "" : "none" }}>
      <FiThumbsUp size={24} className="mb-2 text-success" />

      {message.split("\n").map((str) => (
        <p>{str}</p>
      ))}
    </h5>
  );

  const reportForm = () => {
    return (
      <React.Fragment>
        <FormGroup tag="fieldset" className="" style={{ minWidth: "30vw" }}>
          {reasons.map((r, index) => {
            //   console.log(r, index, typeof reasons);

            if (contentType === "comment") {
              if (r.value === "wrong-device") return;
            }

            return (
              <FormGroup className="my-2" check key={index}>
                <Label check className="radio">
                  <Input
                    type="radio"
                    name={r.name}
                    value={r.value}
                    onChange={changeReason}
                    checked={reason === r.value}
                  />
                  <span className="" style={{ fontSize: "1.1em" }}>
                    {r.name}
                  </span>
                </Label>
              </FormGroup>
            );
          })}
          <div className={`${reason === "other" ? "d-block" : "d-none"}`}>
            <hr />
            <FormGroup row className={`m-2`}>
              <Label className="w-100">
                <div className="d-flex justify-content-between">
                  <span>Description</span>
                  <span className="text-muted">Max 200 Characters</span>
                </div>
              </Label>
              <Input
                type="textarea"
                name="description"
                maxLength={200} // this is the important line
                onChange={changeDescription}
                value={description}
              />
            </FormGroup>
          </div>
        </FormGroup>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Modal
        tabIndex={2}
        isOpen={isOpen}
        toggle={() => dispatch(toggleReportModal())}
        className=""
        centered
      >
        <ModalHeader toggle={() => dispatch(toggleReportModal())}>
          Report {contentType}
        </ModalHeader>
        <ModalBody className="p-2">
          {showError()}
          {message ? showMessage() : reportForm()}
        </ModalBody>
        <ModalFooter>
          {message ? (
            <Button
              color="secondary"
              onClick={() => dispatch(closeReportModal())}
            >
              Close
            </Button>
          ) : (
            <React.Fragment>
              <Button color="danger" onClick={submitReport}>
                Submit
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => dispatch(closeReportModal())}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReportModal;
