import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../../actions/notification";
import useSocket from "../../hooks/useSocket";
import { isAuth } from "../../services/api/auth";

const WebsocketConnector = () => {
  const dispatch = useDispatch();

  var socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on("newNotification", (data) => {
        // alert(`Adding Notification ${socket.id}`);
        dispatch(addNotification(data));
      });

      socket.on("newPost", (data) => {
        // dispatch(addPost(data));
      });

      socket.on("deletePost", (data) => {
        // dispatch(removePost(data));
      });

      // socket.onAny((event, ...args) => {
      //   console.log(`got ${event} =  ${args}`);
      // });
    }
  }, [socket]);

  return null;
};

export default WebsocketConnector;
