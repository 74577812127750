import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { useStore } from "./store";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { GoogleOAuthProvider } from "@react-oauth/google";

/* ----------------------------------- CSS ---------------------------------- */
import "./styles/main.scss";
import "./styles/dark.scss";

/* ---------------------------------- PAGES --------------------------------- */
import Index from "./pages/index";
import Advertise from "./pages/advertise";
import Categories from "./pages/categories";
import Create from "./pages/create";
import Explore from "./pages/explore";
import Login from "./pages/login";
import Notifications from "./pages/notifications";
import Privacy from "./pages/privacy";
import Pro from "./pages/pro";
import Settings from "./pages/settings";
import Signup from "./pages/signup";
import Stats from "./pages/stats";
import Terms from "./pages/terms";
import Confirm from "./pages/confirm";
import NotFound from "./pages/notFound";

/* ------------------------------ DYNAMIC PAGES ----------------------------- */
import Profile from "./pages/profile";
import Category from "./pages/category";
import Wallpaper from "./pages/wallpaper";
import Search from "./pages/search";
import AppInitHandler from "./components/AppInitHandler";
import WebsocketConnector from "./components/Misc/WebsocketConnector";
import ReportModal from "./components/Report";

function App() {
  const localStorageKey = "dark-mode";
  let persistedTheme;
  persistedTheme = localStorage.getItem(localStorageKey);

  let initialState = {
    config: {
      darkMode: persistedTheme ? JSON.parse(persistedTheme) : false,
    },
  };

  const store = useStore(initialState);
  initMessageListener(store);

  store.subscribe(() => {
    const config = store.getState().config;
    if (!config) return;

    if (typeof window !== "undefined")
      localStorage.setItem(localStorageKey, JSON.stringify(config.darkMode));
  });

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <BrowserRouter>
          <AppInitHandler />
          <WebsocketConnector />
          <ReportModal />
          <Routes>
            <Route exact path="/" element={<Index />} />
            <Route exact path="login" element={<Login />} />
            <Route exact path="signup" element={<Signup />} />
            <Route exact path="create" element={<Create />} />
            <Route exact path="advertise" element={<Advertise />} />
            <Route exact path="categories" element={<Categories />} />
            <Route exact path="notifications" element={<Notifications />} />
            <Route exact path="privacy" element={<Privacy />} />
            <Route exact path="pro" element={<Pro />} />
            <Route exact path="stats" element={<Stats />} />
            <Route exact path="explore" element={<Explore />} />
            <Route exact path="terms" element={<Terms />} />
            <Route exact path="settings" element={<Settings />} />
            <Route exact path="confirm" element={<Confirm />} />

            <Route exact path="/profile/:username" element={<Profile />} />
            <Route exact path="/wallpaper/:id" element={<Wallpaper />} />
            <Route exact path="/category/:slug" element={<Category />} />
            <Route exact path="/search/:string" element={<Search />} />

            <Route path="*" element={<NotFound />} />

            {/* <Route path="*" element={<h1>Route does not exist</h1>} /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
