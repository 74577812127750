import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { allNotificationRead } from "../../../actions/notification";
import NotificationList from "../NotificationList";

const NotificationDropdownMenu = (props) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {" "}
      <DropdownItem
        header
        className="bg-dark border-bottom py-3 text-dark"
        style={{ fontSize: "1em" }}
      >
        <div className="d-flex justify-content-between">
          <h4 className="mb-0 my-auto text-dark">Notifications</h4>
          <span className="my-auto">
            <a onClick={() => dispatch(allNotificationRead())}>
              ✓ Mark as read
            </a>
          </span>
        </div>
      </DropdownItem>
      <div className="notification-inner-container" style={{}}>
        <NotificationList />
      </div>
      <DropdownItem
        tag={"span"}
        className="bg-dark border-top display-4 py-3 text-center m-0"
        style={{ fontSize: "1em" }}
      >
        <a href="/notifications"> See All</a>
      </DropdownItem>
    </React.Fragment>
  );
};

export default NotificationDropdownMenu;
