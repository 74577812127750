import React from "react";
import { Link } from "react-router-dom";

import { formatDateDistance } from "../../utils/time";

import Avatar from "../Avatar";

const UserCard = ({
  avatar,
  username,
  subText,
  subTextDark,
  date,
  style,
  onClick,
  children,
  href,
  className,
  //   avatarMedium,
}) => {
  const content = () => {
    return (
      <div
        className={`user-card ${className}`}
        style={style}
        onClick={onClick && onClick}
      >
        <Avatar src={avatar} width="auto" height="40px" />
        <div className="user-card__details">
          <p className="heading-4 font-weight-bold">{username}</p>
          {subText && (
            <p
              className={`heading-4 ${
                subTextDark ? "text-dark" : "text-muted"
              }`}
            >
              {subText}
              {date && (
                <span className="text-muted ml-1">
                  {formatDateDistance(date)}
                </span>
              )}
            </p>
          )}
        </div>
        {children}
      </div>
    );
  };

  return (
    <React.Fragment>
      {href ? <Link to={href}>{content()}</Link> : content()}
    </React.Fragment>
  );
};

export default UserCard;
