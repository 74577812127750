import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DropzoneComponent from "./DropzoneComponent";
import axiosInstance from "../../utils/axios";
import { getCookie } from "../../services/api/auth";
import { Progress } from "reactstrap";
import { BiUpload } from "react-icons/bi";
import SelectOption from "../Misc/SelectOption";
import { increaseWallpapersCount } from "../../actions/user";

import TagInput from "../Inputs/TagInput";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 10,
  width: "100%",
  height: "200px",
  boxSizing: "border-box",
  boxShadow: "0 0px 10px #00000073",
  overflow: "hidden",
};

const thumbInner = {
  width: "100%",
  overflow: "hidden",
};

const CreateWallpaperComponent = () => {
  const availableCategories = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const token = getCookie("token");

  const [values, setValues] = useState({
    files: [],
    description: "",
    category: null, // {}
    tags: [],
    progress: 0,
    error: "",
  });
  const { files, description, category, tags, progress, error } = values;

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleSubmit = (e) => {
    e.preventDefault(); //prevent the form from submitting

    if (!files || files.length == 0) {
      setValues({
        ...values,
        error: "Please select a file",
      });
    } else if (!category) {
      setValues({
        ...values,
        error: "Please select a category",
      });
    } else {
      // setValues({
      //   ...values,
      //   error: "",
      // });

      let formData = new FormData();
      formData.append("wallpaper", files[0]);
      formData.append("description", description);
      formData.append("category", category.value);
      formData.append("tags", tags);

      axiosInstance
        .post("/wallpaper", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            // setProgress(Math.round((100 * data.loaded) / data.total));
            setValues({
              ...values,
              error: "",
              progress: Math.round((100 * data.loaded) / data.total),
            });
          },
        })
        .then((error, data) => {
          console.log("error = ", error);

          dispatch(increaseWallpapersCount());

          setValues({
            ...values,
            files: [],
            description: "",
            category: null,
            tags: [],
            progress: 0,
            error: "",
            //  message: "Wallpaper Created Successfully",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log(error.response.data);
            console.log(error.response.status);

            if (error.response.data) {
              const { code } = error?.response?.data?.error;
              switch (code) {
                case "FILE_MISSING":
                  setValues({
                    ...values,
                    error: "Please select a file before uploading!",
                  });
                  break;
                case "LIMIT_FILE_SIZE":
                  setValues({
                    ...values,
                    error:
                      "File size is too large. Please upload files below 10MB!",
                  });
                  break;
                case "INVALID_TYPE":
                  setValues({
                    ...values,
                    error:
                      "This file type is not supported! Only .png, .jpg and .jpeg files are allowed",
                  });
                  break;

                default:
                  setValues({
                    ...values,
                    error: error?.response?.data?.error
                      ? error?.response?.data?.error
                      : "Sorry! Something went wrong. Please try again later",
                  });
                  break;
              }
              return;
            }
          }
        });
    }
  };

  const handleFiles = (data) => {
    setValues({ ...values, error: "", files: data });
  };

  const handleCategory = (category) => {
    setValues({ ...values, category });
  };

  const handleTags = (tags) => {
    setValues({ ...values, tags }); // toLowerCase
  };

  const thumbs = files.map((file) => (
    <div className="upload-description" style={thumb} key={file.name}>
      <div
        style={{
          ...thumbInner,
          background:
            "linear-gradient(#00000040, #000000), url( " +
            file.preview +
            " ) center/cover no-repeat ",
        }}
      >
        <div className="h-100 p-4 d-flex flex-column" style={{}}>
          <h5
            className="display-5 text-white"
            style={{ textDecoration: "overline" }}
          >
            Description
          </h5>
          <textarea
            className="text-white my-2"
            name="description"
            id="description"
            maxLength={200}
            value={description}
            onChange={(e) =>
              setValues({
                ...values,
                error: "",
                description: e.target.value,
              })
            }
          ></textarea>

          <div>
            <span
              className={`mt-auto badge ${
                description.length >= 200 ? "badge-danger" : "badge-primary"
              }`}
            >
              {description.length} / 200
            </span>
          </div>
        </div>

        {/* <img src={file.preview} style={img} /> */}
      </div>
    </div>
  ));

  const leftColumn = () => {
    return (
      <section className="font-weight-bold">
        <DropzoneComponent handleFiles={handleFiles} />
        <aside style={thumbsContainer}>{thumbs}</aside>{" "}
      </section>
    );
  };

  const rightColumn = () => {
    return (
      <React.Fragment>
        <div className="">
          <SelectOption
            label="Select Category"
            value={category}
            options={availableCategories}
            onSelect={handleCategory}
          />
        </div>

        <div className="mt-3">
          <TagInput
            label="Add Tags"
            description=""
            maxTags={10}
            value={tags}
            handleTags={handleTags}
          />
        </div>

        <hr className="my-4" />

        <div className="d-flex flex-column">
          <div>
            <Progress animated value={progress} className="d-flex">
              <div className="ml-auto pr-2" id="TooltipExample">
                {progress}%
              </div>
            </Progress>
          </div>
          <div className="mt-3">
            <button
              className="btn btn-primary br-2"
              type="submit"
              onClick={handleSubmit}
              style={{ height: "45px" }}
            >
              <BiUpload className="my-auto mr-1" color="#ffffff" size={20} />{" "}
              Create Wallpaper
            </button>
          </div>
          {error && <div className="mt-3 alert alert-danger">{error}</div>}
          {/* <div className="mt-3">
            <p className="text-danger">Error: {error}</p>
          </div> */}
        </div>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <div className="my-4">
        <h4 className="display-5">What's Hot 🔥</h4>
        <h3 className="display-4">Upload Wallpaper</h3>
        <div className="row mt-4">
          <div className="col-xl-8 col-12 mb-4">
            <div className="card">
              <div className="card-body">{leftColumn()}</div>
            </div>
          </div>
          <div className="col-xl-4 col-12">
            <div className="card">
              <div className="card-body">{rightColumn()}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateWallpaperComponent;
