import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../../services/api/auth";

const Private = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth()) {
      navigate(`/login`);
    }
  }, []);
  return <React.Fragment>{isAuth() && children}</React.Fragment>;
};

export default Private;
