import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoginComponent from "../components/Auth/LoginComponent";
import { isAuth } from "../services/api/auth";
import SEO from "../components/Misc/SEO";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    isAuth() && navigate(`/`);
  }, []);

  return (
    <React.Fragment>
      <SEO title="Login" />
      <LoginComponent />
    </React.Fragment>
  );
};

export default Login;
