import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiMessageCircle, FiSend, FiBookmark } from "react-icons/fi";
import moment from "moment";
import LikeButton from "../../../../Buttons/LikeButton";
import CreateComment from "../../../../Comment/CreateComment";
import millify from "millify";

const LightboxDetailsFooter = () => {
  const { currentWallpaper: wallpaper, comments } = useSelector(
    (state) => state.lightbox
  );

  const [values, setValues] = useState({
    liked: false,
    bookmarked: false,
  });
  const { liked, bookmarked } = values;

  useEffect(() => {
    if (wallpaper) {
      setValues({
        ...values,
        liked: wallpaper.isLiked,
        bookmarked: wallpaper.isBookmarked,
      });
    }
  }, [wallpaper]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column border-top p-2 mt-auto">
        <div className="d-flex justify-content-between">
          <div className="d-inline-flex align-items-center">
            <LikeButton
              id={wallpaper._id}
              liked={liked}
              handleChange={(value) => setValues({ ...values, liked: value })}
              size="lg"
              type="wallpaper"
            />{" "}
            {/* <span style={{ marginLeft: "-5px" }}>
              {millify(wallpaper.votesCount + 5090)}
            </span> */}
            {/* {wallpaper.votesCount} Likes */}
            {/* <FiMessageCircle className="mt-2" size={30} color="#7d7d7d" /> */}
          </div>
          <div className="d-inline-flex">
            {/* <FiSend className="my-auto" size={26} color="#7d7d7d" /> */}
          </div>
        </div>
        <div className="ml-2 d-flex flex-column">
          <span className="mt-2">
            {comments && millify(comments.count)} Comments
          </span>
          <span className="mt-2 text-muted text-sm">
            {wallpaper &&
              wallpaper.createdAt &&
              moment(wallpaper.createdAt).fromNow()}
          </span>
        </div>
      </div>

      <CreateComment wallpaperId={wallpaper._id} />
    </React.Fragment>
  );
};

export default LightboxDetailsFooter;
