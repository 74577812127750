import React, { useState } from "react";
import Layout from "../Layout";
import CustomScrollbar from "../Misc/CustomScrollbar";
import Private from "../Auth/Private";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import GeneralSettings from "./General";
import SecuritySettings from "./Security";

const SettingsComponent = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const pageContent = () => {
    return (
      <React.Fragment>
        <Nav tabs className="custom-top-nav mt-4">
          <NavItem>
            <NavLink
              className={`${activeTab == "1" && "active"}`}
              onClick={() => {
                toggle("1");
              }}
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab == "2" && "active"}`}
              onClick={() => {
                toggle("2");
              }}
            >
              Privacy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab == "3" && "active"}`}
              onClick={() => {
                toggle("3");
              }}
            >
              Notification
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab == "4" && "active"}`}
              onClick={() => {
                toggle("4");
              }}
            >
              Billing
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab == "5" && "active"}`}
              onClick={() => {
                toggle("5");
              }}
            >
              Security
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="py-5" activeTab={activeTab}>
          <TabPane tabId="1">
            <GeneralSettings />
          </TabPane>
          <TabPane tabId="2">
            <h4>Under Development</h4>
          </TabPane>
          <TabPane tabId="3">
            <h4>Under Development</h4>
          </TabPane>
          <TabPane tabId="4">
            <h4>Under Development</h4>
          </TabPane>
          <TabPane tabId="5">
            <SecuritySettings />
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  };

  return <React.Fragment>{pageContent()}</React.Fragment>;
};

export default SettingsComponent;
