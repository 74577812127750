import { useNavigate } from "react-router-dom";

import { loginWithGoogleOauth, authenticate } from "../../services/api/auth";
import { FaGoogle } from "react-icons/fa";

import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { initUserData } from "../../actions/user";

const LoginGoogle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleToken = async (code) => {
    await loginWithGoogleOauth({ code })
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        } else {
          authenticate(data, () => {
            if (data && data.user && data.user.username) {
              dispatch(initUserData(data.user.username)).then(() => {
                navigate(`/`);
              });
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => await handleToken(code),
    onError: (err) => console.error(err),
    flow: "auth-code",
  });

  return (
    <div className="">
      <button
        style={{ height: "60px", padding: "2px" }}
        className="btn btn-primary btn-block br-2 overflow-hidden"
        onClick={() => login()}
      >
        <span className="btn-light br-2 d-flex align-items-center h-100 px-3">
          <FaGoogle size="1.5em" className="ml-1 mr-3" /> Signin with Google
        </span>
      </button>
    </div>
  );
};

export default LoginGoogle;
