import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import LightboxContent from "../components/Lightbox/LightboxContent";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import SEO from "../components/Misc/SEO";

const Wallpaper = () => {
  const { id } = useParams();

  return (
    <Layout>
      <SEO title="Wallpaper" hasDynamicContent />
      <div className="inner-content w-100">
        <div className="full-page-wallpaper h-100 p-lg-5">
          <div className="modal bg-dark br-2">
            <LightboxContent wallpaperId={id} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wallpaper;
