import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Button } from "reactstrap";

const NotFound = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="d-flex flex-row align-items-center">
        <h1 class="mr-2">404</h1>
        <div
          className="my-auto"
          style={{
            borderLeft: "1px solid #7d7d7d50",
            margin: "10px",
            height: "25px",
          }}
        ></div>
        <h2 class="ml-2">Page Not Found</h2>
      </div>

      <div className="mt-4">
        <Link to="/" className="">
          <Button color="secondary" className="d-flex align-items-center">
            <FaArrowLeft className="mr-2" /> <span>BACK TO HOMEPAGE</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
