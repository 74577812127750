import React, { useState, useEffect } from "react";
import LazyScroll from "../Misc/LazyScroll";
import ImageGallery from "../ImageGallery";
import { addGalleryData, setGalleryData } from "../../actions/gallery";
import { retrieveWallpapersByUser } from "../../services/api/wallpaper";
import { useDispatch, useSelector } from "react-redux";

const CreatedTabContent = ({ username }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    wallpapers: [],
    count: 0,
    error: "",
    loading: true,
    hasMore: true,
  });
  const { error, loading, hasMore, wallpapers, count } = values;
  const galleryData = useSelector((state) => state.gallery.data);

  useEffect(() => {
    retieveCreatedWallpapers();
  }, [username]);

  useEffect(() => {
    dispatch(setGalleryData(wallpapers));
  }, [wallpapers]);

  const retieveCreatedWallpapers = () => {
    if (count > 0 && wallpapers.length >= count) {
      console.log(
        "Not Going to get wallpapers, count is less than existing wallpapers"
      );
      return;
    }

    if (username) {
      retrieveWallpapersByUser(username, wallpapers.length).then((result) => {
        if (result.error) {
          console.error(result.error);

          setValues({
            loading: false,
            error: result.error,
            hasMore: wallpapers.length < 1 ? false : true,
          });
        } else {
          let newArray = [...wallpapers, ...result.data];

          setValues({
            loading: false,
            count: result.count,
            wallpapers: newArray,
            hasMore:
              newArray.length >= result.count || result.data.length < 20
                ? false
                : true,
          });
        }
      });
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        "Loading Gallery"
      ) : (
        <LazyScroll
          dataLength={galleryData.length}
          next={() => retieveCreatedWallpapers()}
          hasMore={hasMore}
        >
          <ImageGallery />
        </LazyScroll>
      )}
    </React.Fragment>
  );
};

export default CreatedTabContent;
