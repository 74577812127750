import React from "react";
import { FiHeart, FiMessageCircle, FiDownload, FiEye } from "react-icons/fi";
import Avatar from "../Avatar";
import millify from "millify";

const WallpaperCustomRenderer = ({
  index,
  left,
  top,
  key2,
  photo,
  margin,
  direction,
  onClick,
}) => {
  const cont = {};
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };

  return (
    <div
      key={index}
      className="container-overlay"
      style={{
        margin,
        height: photo.height,
        width: photo.width,
        ...cont,
      }}
      onClick={onClick ? handleClick : null}
    >
      <img
        src={`${process.env.REACT_APP_S3_BUCKET}/${photo.src}`}
        height={photo.height}
        width={photo.width}
        alt="wallpaper"
      />
      <div
        className="overlay"
        style={{
          height: photo.height,
          width: photo.width,
        }}
      >
        <div className="overlay-content">
          <div className="d-flex flex-row justify-content-between mt-auto p-2 w-100">
            <div className="d-flex align-items-center">
              <Avatar
                // onClick={() => onClick()}
                width="auto"
                height="40px"
                src={photo.createdBy && photo.createdBy.avatar}
                size="sm"
              />

              <span
                className="ml-3"
                style={{ textShadow: "1px 1px 20px #000000" }}
              >
                {photo && photo.createdBy
                  ? photo.createdBy.fullName
                    ? photo.createdBy.fullName
                    : photo.createdBy.username && photo.createdBy.username
                  : null}
              </span>
            </div>
            <div className="d-flex align-items-center">
              {/* <span>
                <FiHeart size={22} /> {photo && photo.votes}
              </span>
              <span>
                <FiMessageCircle size={22} /> {photo && photo.comments}
              </span> */}
              <span>
                <FiEye size={22} /> {photo && millify(photo.views)}
              </span>
              <span>
                <FiDownload size={22} /> {photo && millify(photo.downloads)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WallpaperCustomRenderer;
