import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Comments from "../../../../Comment";
import CustomScrollbar from "../../../../Misc/CustomScrollbar";

import ReadMoreReact from "read-more-react";

import LightboxDetailsHeader from "./Header";
import LightboxDetailsFooter from "./Footer";

const MainRender = () => {
  const { currentWallpaper: wallpaper } = useSelector(
    (state) => state.lightbox
  );

  return (
    <React.Fragment>
      <LightboxDetailsHeader />

      <div className="h-100">
        <CustomScrollbar>
          <div className="p-3">
            {wallpaper && wallpaper.description && (
              <React.Fragment>
                <div className="">
                  <h5 className="display-5">Description</h5>
                  {/* ADD Edit */}
                  {/* {wallpaper.description} */}
                  <ReadMoreReact
                    key={wallpaper.description}
                    text={wallpaper.description}
                    readMoreText="See more"
                  />{" "}
                </div>
                <hr />
              </React.Fragment>
            )}

            <div className="">
              <h5 className="display-5">Comments</h5>
              <Comments />
            </div>
          </div>
        </CustomScrollbar>
      </div>

      <LightboxDetailsFooter />
    </React.Fragment>
  );
};

export default MainRender;
