import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import queryString from "query-string";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

/* --------------------------------- FEED --------------------------------- */
export const retrieveHotFeed = (offset = 0, timeframe = "all", category) => {
  let queryURL = `${API}/feed/hot/${timeframe}?${queryString.stringify({
    offset,
    category,
  })}`;

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: token,
    },
    //   body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

// return fetch(`${API}/feed/latest/${offset}?category=${category}`, {

export const retrieveLatestFeed = (offset = 0, category) => {
  let queryURL = `${API}/feed/latest?${queryString.stringify({
    offset,
    category,
  })}`;

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: token,
    },
    //   body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const retrieveTimelineFeed = (offset = 0, token) => {
  let queryURL = `${API}/feed/timeline?${queryString.stringify({
    offset,
  })}`;

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    //   body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
