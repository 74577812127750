import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineSearch } from "react-icons/md";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { useMediaQuery } from "react-responsive";

const SearchBar = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    navigate(`/search/${query}`);
  }
  let isDesktop = useMediaQuery({ query: "(min-width: 1368px)" });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <InputGroup
          className="mr-auto br-2 overflow-hidden custom-input-group"
          style={{
            width: isDesktop ? "300px" : "auto",
            height: "38px",
          }}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {/* <i className="mdi mdi-magnify"></i> */}
              <MdOutlineSearch size={24} color="#7d7d7d" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            maxLength={40}
          />
        </InputGroup>
      </form>
    </React.Fragment>
  );
};

export default SearchBar;
