const initialData = {
  count: 0,
  data: [],
};

// BOOKMARK REDUCER
const bookmark = (state = initialData, action) => {
  switch (action.type) {
    // Bookmark
    case "SET_BOOKMARKS":
      return { ...state, bookmarks: action.data };
    case "ADD_BOOKMARK":
      return { ...state, bookmarks: action.data };
    case "DELETE_BOOKMARK":
      return { ...state, bookmarks: action.data };
    default:
      return state;
  }
};

export default bookmark;
