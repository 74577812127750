import { getCookie } from "../services/api/auth";
import {
  readNotification,
  retrieveNotifications,
  readUserNotifications,
} from "../services/api/notification";
import { increaseFollowersCount } from "./user";

// export const addNotification = (notification) => ({
//   type: "ADD_NOTIFICATION",
//   payload: notification,
// });

export const addNotification = (notification) => (dispatch) => {
  // add Notification
  dispatch({
    type: "ADD_NOTIFICATION",
    payload: notification,
  });

  if (notification.type === "follow") dispatch(increaseFollowersCount());

  return;
};

export const fetchNotifications =
  (offset = 0) =>
  async (dispatch) => {
    const token = getCookie("token");

    await retrieveNotifications(offset, token).then((data) => {
      if (!data) throw Error("API Server didn't respond");

      if (data.error) {
        throw data.error;
      } else {
        // return data;

        if (offset === 0) {
          return dispatch({
            type: "FETCH_NOTIFICATIONS",
            payload: data,
          });
        } else {
          return dispatch({
            type: "FETCH_MORE_NOTIFICATIONS",
            payload: data,
          });
        }
      }
    });
  };

export const singleNotificationRead = (id) => async (dispatch) => {
  const token = getCookie("token");

  const res = await readNotification(id, token).then((data) => {
    if (data) {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    }
  });

  // update Comment
  dispatch({
    type: "READ_SINGLE_NOTIFICATION",
    payload: {
      id,
    },
  });

  return;
};

export const allNotificationRead = () => async (dispatch) => {
  const token = getCookie("token");

  const res = await readUserNotifications(token).then((data) => {
    if (data) {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    }
  });

  // update Comment
  dispatch({
    type: "READ_ALL_NOTIFICATIONS",
    //   payload: {
    //     data: finalData,
    //   },
  });

  return;
};

export const clearNotifications = () => ({
  type: "CLEAR_NOTIFICATIONS",
});
