import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadCommentsByWallpaperId } from "../../actions/comment";
import { getCookie } from "../../services/api/auth";
import { retrieveCommentRepliesByCommentId } from "../../services/api/commentReply";
import SingleCommentReply from "./SingleCommentReply";
import millify from "millify";

import { Spinner } from "reactstrap";

import {
  FiMoreVertical,
  FiEdit,
  FiShare2,
  FiTrash2,
  FiFlag,
  FiCheck,
  FiX,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";

const CommentReplies = ({ comment }) => {
  const token = getCookie("token");
  const { _id: commentId, repliesCount } = comment;

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    commentReplies: [],
    hasMore: true,
    count: repliesCount ? repliesCount : 0,
    loading: false,
    error: "",
  });
  const { commentReplies, hasMore, count, error, loading } = values;

  // useEffect(() => {
  //   //   dispatch(loadCommentsByWallpaperId(wallpaper._id, 0));
  //   if (isOpen) {
  //     getCommentReplies();
  //   }
  // }, [comment]);

  const getCommentReplies = async (offset = 0) => {
    if (loading) return; // already searching....

    setValues({ ...values, loading: true });

    retrieveCommentRepliesByCommentId(commentId, offset, token).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
        return;
      }

      setValues({
        ...values,
        loading: false,
        error: "",
        // hasMore: data.data.length < 10 ? false : true,
        hasMore:
          commentReplies.length + data.data.length < data.count ? true : false,
        commentReplies:
          offset < 1 ? data.data : [...commentReplies, ...data.data],
        count: data.count,
      });
    });
  };

  const handleUpdate = (newData) => {
    let tempData = commentReplies.map((c) =>
      c._id === newData._id ? newData : c
    );

    setValues({ ...values, commentReplies: tempData });
  };

  const handleDelete = (commentReplyId) => {
    let tempData = commentReplies.filter((c) => c._id !== commentReplyId);

    setValues({ ...values, commentReplies: tempData });
  };

  const toggleOpen = () => {
    // check if it was close -> get commentReplies
    if (!isOpen && commentReplies.length < 1) {
      getCommentReplies();
    }
    // open
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-2">
      {count > 0 && (
        <a className="text-primary" onClick={() => toggleOpen()}>
          {isOpen ? (
            <React.Fragment>
              Hide Replies <FiChevronUp className="" color="" size={15} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              View {millify(count)} Replies{" "}
              <FiChevronDown className="text-primary" color="" size={15} />
            </React.Fragment>
          )}
        </a>
      )}
      {isOpen && commentReplies && commentReplies.length > 1 && (
        <React.Fragment>
          {commentReplies.map((c, i) => {
            return (
              <SingleCommentReply
                commentReply={c}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                key={i}
              />
            );
          })}

          <div className="mt-4">
            <a
              className={`text-primary ${hasMore ? "" : "d-none"}`}
              // disabled={!commentReplies.hasMore}
              onClick={() => getCommentReplies(commentReplies.length)}
            >
              Load More{" "}
              <FiChevronDown className="text-primary" color="" size={15} />
            </a>
          </div>
        </React.Fragment>
      )}{" "}
      <div>
        {loading && <Spinner className="my-auto" size="sm" color="primary" />}
      </div>
    </div>
  );
};

export default CommentReplies;
