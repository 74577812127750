import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  FiMoreVertical,
  FiEdit,
  FiShare2,
  FiTrash2,
  FiFlag,
  FiCheck,
  FiX,
} from "react-icons/fi";

import LikeButton from "../Buttons/LikeButton";
import { getCookie, isAuth } from "../../services/api/auth";
import Avatar from "../Avatar";
import CreateCommentReply from "./CreateCommentReply";
import { openReportModal } from "../../actions/report";
import {
  removeCommentReply,
  updateCommentReply,
} from "../../services/api/commentReply";
import { closeLightbox } from "../../actions/lightbox";

const SingleCommentReply = ({ commentReply, handleUpdate, handleDelete }) => {
  const token = getCookie("token");

  const [openReplyCall, createOpenReplyCall] = useState(() => () => {});
  const dispatch = useDispatch();
  const { currentWallpaper: wallpaper } = useSelector(
    (state) => state.lightbox
  );

  const [values, setValues] = useState({
    editing: false,
    content: commentReply.content,
    liked: false,
    loading: false,
    error: "",
  });
  const { editing, content, liked } = values;

  useEffect(() => {
    if (commentReply) {
      setValues({
        ...values,
        liked: commentReply.isLiked,
        content: commentReply.content,
      });
    }
  }, [commentReply]);

  const handleEdit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });

    updateCommentReply(commentReply._id, { content }, token).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
        return;
      }

      setValues({ ...values, editing: false, error: "", loading: false });

      // send update to parent
      handleUpdate(data);
    });
  };

  const deleteCommentReply = (e) => {
    e.preventDefault();

    let answer = window.confirm(
      "Are you sure you want to delete this comment reply?"
    );
    if (answer) {
      removeCommentReply(commentReply._id, token).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          return;
        }

        // send update to parent
        handleDelete(commentReply._id);
      });
    }

    // handleDelete()
  };

  const editRender = () => {
    return (
      <React.Fragment>
        <div className="d-flex flex-column w-100">
          <textarea
            className="h-100 w-100 p-2"
            name=""
            id=""
            rows="3"
            value={content}
            onChange={(e) => setValues({ ...values, content: e.target.value })}
            maxLength={200}
          ></textarea>

          <div className="d-flex flex-row pl-2 mt-2">
            <button
              type="button"
              className="btn btn-primary h-auto ml-auto"
              onClick={handleEdit}
            >
              {/* <FiCheck className="" color="#ffffff" size={18} /> */}
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger h-auto ml-2"
              onClick={() => setValues({ ...values, editing: false })}
            >
              {/* <FiX className="" color="#ffffff" size={18} /> */}
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const menu = () => {
    return (
      <React.Fragment>
        <UncontrolledDropdown className="ml-2" inNavbar={false}>
          <DropdownToggle tag="a" caret={false}>
            <FiMoreVertical
              className="my-auto h-100"
              color="#7d7d7d"
              size={15}
            />
          </DropdownToggle>
          <DropdownMenu right positionFixed={false}>
            {isAuth() && isAuth()._id == commentReply.createdBy._id && (
              <DropdownItem
                onClick={() => setValues({ ...values, editing: true })}
              >
                <FiEdit className="mr-2" color="#7d7d7d" size={15} /> Edit
              </DropdownItem>
            )}

            {isAuth() && isAuth()._id == commentReply.createdBy._id ? null : (
              <DropdownItem
                onClick={() =>
                  dispatch(
                    openReportModal({
                      contentType: "comment-reply",
                      contentId: commentReply._id,
                    })
                  )
                }
              >
                <FiFlag className="mr-2" color="#7d7d7d" size={15} /> Report
              </DropdownItem>
            )}

            {isAuth() && isAuth()._id == commentReply.createdBy._id && (
              <React.Fragment>
                <DropdownItem divider />
                <DropdownItem
                  className="text-danger"
                  onClick={deleteCommentReply}
                >
                  <FiTrash2 className="mr-2" color="" size={15} /> Delete
                </DropdownItem>
              </React.Fragment>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </React.Fragment>
    );
  };

  const navigate = useNavigate();
  const profileClicked = () => {
    if (
      commentReply &&
      commentReply.createdBy &&
      commentReply.createdBy.username
    ) {
      // push
      navigate(`/profile/${commentReply.createdBy.username}`);

      setTimeout(() => {
        // close modal
        dispatch(closeLightbox());
      }, 0);
    }
  };

  const contentRender = () => {
    return (
      <React.Fragment>
        <Media body>
          <div className="d-flex flex-column">
            <p className="mb-0">
              <a className="font-weight-bold" onClick={profileClicked}>
                {commentReply &&
                commentReply.createdBy &&
                wallpaper &&
                wallpaper.createdBy &&
                commentReply.createdBy._id == wallpaper.createdBy._id ? (
                  <Badge color="secondary" pill style={{ fontSize: "90%" }}>
                    {commentReply.createdBy.username}
                  </Badge>
                ) : (
                  commentReply.createdBy.username
                )}
              </a>
            </p>
            <p
              className={`${
                commentReply &&
                isAuth() &&
                commentReply.content &&
                commentReply.content.includes(`@${isAuth().username}`) &&
                "bg-highlight"
              }`}
            >
              {commentReply.content}
            </p>
            <span className="text-muted">
              {commentReply && commentReply.edited && "edited"}
            </span>
            <p className="text-sm text-muted mb-0">
              {moment(commentReply.createdAt).fromNow()}{" "}
              <a
                className="text-muted font-weight-bold ml-2"
                onClick={() => openReplyCall()}
              >
                Reply
              </a>
            </p>
          </div>

          {/* Create Comment Reply */}

          {commentReply &&
            commentReply.createdBy &&
            commentReply.createdBy.username && (
              <CreateCommentReply
                commentId={commentReply.comment}
                createOpenReplyCall={createOpenReplyCall}
                replyingTo={commentReply.createdBy.username}
              />
            )}
        </Media>
        <Media right className="ml-3 d-flex flex-row">
          {isAuth() && isAuth()._id == commentReply.createdBy._id ? null : (
            <LikeButton
              id={commentReply._id}
              liked={liked}
              // handleLike={() => handleVote(commentReply._id)}

              handleChange={(value) => setValues({ ...values, liked: value })}
              size="sm"
              type="comment-reply"
            />
          )}

          {menu()}
        </Media>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Media className="mt-4">
        <Media left>
          <Avatar
            onClick={profileClicked}
            className="mr-2"
            width="auto"
            height="40px"
            src={commentReply && commentReply.createdBy.avatar}
            size="sm"
          />
        </Media>
        {editing ? editRender() : contentRender()}
        {/* {contentRender()} */}
      </Media>

      {/* Create Comment Reply */}
      {/* <CreateCommentReply
        commentId={commentReply.commentReply}
        createOpenReplyCall={createOpenReplyCall}
      /> */}
    </React.Fragment>
  );
};

export default SingleCommentReply;
