import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const createCommentReply = (data, token) => {
  return fetch(`${API}/comment-reply`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateCommentReply = (commentReplyId, data, token) => {
  return fetch(`${API}/comment-reply/${commentReplyId}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeCommentReply = (commentReplyId, token) => {
  return fetch(`${API}/comment-reply/${commentReplyId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const retrieveCommentRepliesByCommentId = (commentId, offset, token) => {
  let queryURL = `${API}/comment-reply/comment/${commentId}`;

  if (offset) {
    queryURL = queryURL + `?offset=${offset}`;
  }

  return fetch(queryURL, {
    method: "GET",
    headers: token
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const voteCommentReply = (commentReplyId, token) => {
  return fetch(`${API}/comment-reply/${commentReplyId}/vote`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
