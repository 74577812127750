import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { isAuth } from "../services/api/auth";
import SignupComponent from "../components/Auth/SignupComponent";
import SEO from "../components/Misc/SEO";

const Signup = () => {
  const navigate = useNavigate();
  useEffect(() => {
    isAuth() && navigate(`/`);
  }, []);

  return (
    <React.Fragment>
      <SEO title="Signup" />
      <SignupComponent />
    </React.Fragment>
  );
};

export default Signup;
