import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  FiX,
  FiHome,
  FiSend,
  FiSettings,
  FiBarChart2,
  FiHelpCircle,
  FiFacebook,
  FiTwitter,
  FiInstagram,
} from "react-icons/fi";

import { FaDiscord } from "react-icons/fa";

import { TbBrandTelegram } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { RiAdvertisementFill } from "react-icons/ri";
import { MdManageSearch } from "react-icons/md";

import { Link } from "react-router-dom";
import { isAuth } from "../../services/api/auth";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../Avatar";
import millify from "millify";
import MediaQuery from "react-responsive";

const SidebarContent = ({ closeSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const darkMode = useSelector((state) => state.config.darkMode);
  const { wallpapers, followers, following, username, fullName } = useSelector(
    (state) => state.user
  );

  const [logoURL, setLogoURL] = useState("");

  useEffect(() => {
    if (darkMode) {
      setLogoURL("/images/logo-white.svg");
    } else {
      setLogoURL("/images/logo-dark.svg");
    }
  }, [darkMode]);

  const pageContent = () => {
    return (
      <div
        className="bg-dark d-flex flex-column h-100"
        // id="sidebar-wrapper"
        // style={{ borderRight: "1px solid #302f34" }}
      >
        <div className="sidebar-heading d-flex align-items-center justify-content-between">
          <Link to="/">
            <img
              src={logoURL}
              key={logoURL}
              alt="Logo"
              style={{
                // height: "40px",
                height: "45px",
              }}
            />
          </Link>

          <MediaQuery maxWidth={1024}>
            <button className="btn p-0" onClick={() => closeSidebar()}>
              <FiX size={24} color="#7d7d7d" />
            </button>
          </MediaQuery>
        </div>

        <div
          className="px-5 flex-column d-flex"
          style={{ height: "calc(100vh - 80px)" }}
        >
          {/* {isAuth() ? userContent() : ""} */}

          <div className="container-fluid text-center">
            <div className="mt-5">
              <Avatar
                onClick={() =>
                  username
                    ? navigate(`/profile/${username}`)
                    : console.log("Login Required")
                }
                width="auto"
                height="70px"
                self
              />
            </div>
            <div className="my-3">
              {fullName && (
                <Link to={username ? `/profile/${username}` : "/"}>
                  <h5 className="mb-1">{fullName}</h5>
                </Link>
              )}

              {username && (
                <Link to={username ? `/profile/${username}` : "/"}>
                  <p className="text-sm font-weight-bold text-muted">
                    @{username}
                  </p>
                </Link>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <h5 className="mb-1">{millify(wallpapers)}</h5>
                <p className="text-sm text-muted font-weight-bold">
                  Wallpapers
                </p>
              </div>
              <div className="col-4">
                <h5 className="mb-1">{millify(followers)}</h5>
                <p className="text-sm text-muted font-weight-bold">Followers</p>
              </div>
              <div className="col-4">
                <h5 className="mb-1">{millify(following)}</h5>
                <p className="text-sm text-muted font-weight-bold">Following</p>
              </div>
            </div>
          </div>
          <hr />

          <div className="sidebar-links mt-4">
            <Link to="/">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                <FiHome color="#7d7d7d" size={24} />
                <p className="ml-2 my-auto">Home</p>
              </a>
            </Link>

            <Link to="/categories">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/categories" ? "active" : ""
                }`}
              >
                <BiCategory color="#7d7d7d" size={24} />
                <p className="ml-2 my-auto">Categories</p>
              </a>
            </Link>

            <Link to="/explore">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/explore" ? "active" : ""
                }`}
              >
                <MdManageSearch color="#7d7d7d" size={27} />
                <p className="ml-2 my-auto">Explore</p>
              </a>
            </Link>

            {/* <Link to="/advertise">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/advertise" ? "active" : ""
                }`}
              >
                <RiAdvertisementFill color="#7d7d7d" size={24} />
                <p className="ml-2 my-auto">Advertise</p>
              </a>
            </Link> */}

            <Link to="/settings">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/settings" ? "active" : ""
                }`}
              >
                <FiSettings color="#7d7d7d" size={24} />
                <p className="ml-2 my-auto">Settings</p>
              </a>
            </Link>

            <Link to="/stats">
              <a
                className={`mt-4 py-1 sidebar-nav-item d-flex ${
                  location.pathname === "/stats" ? "active" : ""
                }`}
              >
                <FiBarChart2 color="#7d7d7d" size={24} />
                <p className="ml-2 my-auto">Stats</p>
              </a>
            </Link>
          </div>

          <div className="mt-auto mb-4">
            <div className="d-flex mb-3">
              <a
                href="https://twitter.com/WallHippo"
                className="mr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiTwitter color="#7d7d7d" size={20} />
              </a>
              <a
                href="https://www.instagram.com/wallhippo_official/"
                className="mr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiInstagram color="#7d7d7d" size={20} />
              </a>
              <a
                href="https://www.facebook.com/people/WallHippo/100086874872034/"
                className="mr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiFacebook color="#7d7d7d" size={20} />
              </a>
              <a
                href="https://discord.gg/r9EGZ9vCZj"
                className="mr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord color="#7d7d7d" size={20} />
              </a>
              <a
                href="https://t.me/wallhippo"
                className="mr-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TbBrandTelegram color="#7d7d7d" size={20} />
              </a>
            </div>
            <p className="text-muted mb-2">
              {process.env.REACT_APP_APP_VERSION}
            </p>

            <a className="mt-auto py-1 sidebar-nav-item d-flex" href="#!">
              <FiHelpCircle className="my-auto" color="#7d7d7d" size={14} />

              <p className="ml-2 my-auto" style={{ fontSize: "0.9em" }}>
                Help and Support
              </p>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return <React.Fragment>{pageContent()}</React.Fragment>;
};

export default SidebarContent;
