import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const createReport = (data, token) => {
  return fetch(`${API}/report`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
