import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import CategoryGallery from "../components/Category";
import { Nav, NavItem, NavLink } from "reactstrap";
import GalleryHeader from "../components/ImageGallery/GalleryHeader";
import SEO from "../components/Misc/SEO";

const Category = () => {
  const { slug } = useParams();
  const [scrollToTop, setScrollToTop] = useState(() => () => {});

  return (
    <Layout>
      <SEO title={`Category`} hasDynamicContent />
      <div className="inner-content w-100 d-flex flex-column">
        <div
          className="container-fluid px-sm-5 px-3 pb-4"
          // style={{ maxWidth: "1200px" }}
        >
          <GalleryHeader scrollToTop={scrollToTop} hot latest />
        </div>
        <CustomScrollbar page={true} setScrollToTop={setScrollToTop}>
          <CategoryGallery slug={slug} />
        </CustomScrollbar>
      </div>
    </Layout>
  );
};

export default Category;
