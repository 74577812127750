import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Gallery from "react-photo-gallery";
import { useDispatch, useSelector } from "react-redux";
import CategoryCustomRenderer from "../ImageGallery/CategoryCustomRenderer";
import ContainerDimensions from "react-container-dimensions";

const CategoriesList = () => {
  const navigate = useNavigate();
  const availableCategories = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  const [cat, setCat] = useState(null);
  useEffect(() => {
    let tempData = availableCategories.map((d, index) => {
      let tempD = d;

      tempD.height = 108;
      tempD.width = 192;
      // tempD.src = `wallpapers/thumbnails/0299842a-cf74-4047-bf41-f61a1dc021ce-FsaX5pkOieb369045-57d5-46a2-a3e9-2c0f4c1d434f-thumbnail.jpg`;

      return tempD;
    });

    setCat(tempData);
  }, [availableCategories]);

  const renderCategories = () => {
    return (
      <React.Fragment>
        <div className="category-gallery">
          <ContainerDimensions>
            {({ width, height }) => {
              let columns = 1;
              if (width >= 500) columns = 2;
              if (width >= 900) columns = 3;
              if (width >= 1200) columns = 4;
              return (
                <Gallery
                  photos={cat}
                  columns={columns}
                  direction={"column"}
                  renderImage={CategoryCustomRenderer}
                  margin={10}
                  onClick={(event, { photo, index }) => {
                    navigate(`/category/${photo.slug}`);
                    // openLightbox(index);
                  }}
                />
              );
            }}
          </ContainerDimensions>
        </div>

        {/* <div className="d-flex flex-row flex-wrap">
            {availableCategories.map((category, i) => {
              return (
                <React.Fragment>
                  <div
                    className="m-2 d-flex align-items-center justify-content-center"
                    style={{
                      height: "108px",
                      width: "192px",
                      backgroundColor: "#2d2d2d",
                    }}
                  >
                    <span className="">{category.label}</span>
                  </div>
                </React.Fragment>
              );
            })}{" "}
          </div> */}
      </React.Fragment>
    );
  };

  return <div>{cat && cat.length > 0 && renderCategories()}</div>;
};

export default CategoriesList;
