const initialCategories = [];

// CATEGORY REDUCER
const category = (state = initialCategories, action) => {
  switch (action.type) {
    case "LOAD_CATEGORIES":
      return action.payload;

    case "SET_HOT_CATEGORY_FEED":
      state = state.map((s) => {
        if (s.value === action.payload.id) {
          s.hot[action.payload.timeframe] = {
            data: action.payload.data,
            count: action.payload.count,
            hasMore:
              action.payload.data.length < action.payload.count ? true : false,
            initialized: true,
          };
        }
        return s;
      });
      return state;

    case "LOAD_HOT_CATEGORY_FEED":
      state = state.map((s) => {
        if (s.value === action.payload.id) {
          s.hot[action.payload.timeframe] = {
            initialized: s.hot[action.payload.timeframe].initialized,
            data: [
              ...s.hot[action.payload.timeframe].data,
              ...action.payload.data,
            ],
            count: action.payload.count,
            hasMore:
              [...s.hot[action.payload.timeframe].data, ...action.payload.data]
                .length < action.payload.count
                ? true
                : false,
          };
        }
        return s;
      });
      return state;

    case "SET_LATEST_CATEGORY_FEED":
      state = state.map((s) => {
        if (s.value === action.payload.id) {
          s.latest = {
            data: action.payload.data,
            count: action.payload.count,
            hasMore:
              action.payload.data.length < action.payload.count ? true : false,
            initialized: true,
          };
        }
        return s;
      });
      return state;

    case "LOAD_LATEST_CATEGORY_FEED":
      state = state.map((s) => {
        if (s.value === action.payload.id) {
          s.latest = {
            initialized: s.latest.initialized,
            data: [...s.latest.data, ...action.payload.data],
            count: action.payload.count,
            hasMore:
              [...s.latest.data, ...action.payload.data].length <
              action.payload.count
                ? true
                : false,
          };
        }
        return s;
      });
      return state;

    default:
      return state;
  }
};

export default category;
