import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

import ImageGallery from "../ImageGallery";
import {
  loadHotCategoryFeed,
  loadLatestCategoryFeed,
} from "../../actions/category";
import { setGalleryData } from "../../actions/gallery";

import LazyScroll from "../Misc/LazyScroll";
import { getCategoryBySlug } from "../../utils/category";

const CategoryGallery = ({ slug }) => {
  const dispatch = useDispatch();
  const { activeTab, timeframe } = useSelector((state) => state.gallery);

  const availableCategories = useSelector((state) => state.categories);
  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    if (availableCategories && availableCategories.length > 0 && slug) {
      let category = getCategoryBySlug(slug, availableCategories);
      setCurrentCategory({
        slug: category.slug,
        name: category.label,
        value: category.value,
        src: category.src,
        hot: category.hot,
        latest: category.latest,
      });
      // setCurrentCategory(category);
    }
  }, [availableCategories, slug]);

  useEffect(() => {
    if (currentCategory) {
      initFeed(activeTab, timeframe);
      cloneSetGalleryData();
    }
  }, [activeTab, timeframe, currentCategory]);

  const initFeed = (tab, timeframe) => {
    switch (tab) {
      case "hot":
        if (!currentCategory.hot[timeframe].initialized) {
          dispatch(loadHotCategoryFeed(0, timeframe, currentCategory.value));
        }
        break;
      case "latest":
        if (!currentCategory.latest.initialized) {
          dispatch(loadLatestCategoryFeed(0, currentCategory.value));
        }
        break;

      default:
        break;
    }
  };

  const cloneSetGalleryData = () => {
    switch (activeTab) {
      case "hot":
        dispatch(setGalleryData(currentCategory.hot[timeframe].data));
        break;
      case "latest":
        dispatch(setGalleryData(currentCategory.latest.data));
        break;

      default:
        break;
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "hot":
        return (
          <React.Fragment>
            {currentCategory && (
              <LazyScroll
                dataLength={currentCategory.hot[timeframe].data.length}
                next={() =>
                  dispatch(
                    loadHotCategoryFeed(
                      currentCategory.hot[timeframe].data.length,
                      timeframe,
                      currentCategory.value
                    )
                  )
                }
                hasMore={currentCategory.hot[timeframe].hasMore}
              >
                <ImageGallery />
              </LazyScroll>
            )}
          </React.Fragment>
        );
        return;
      case "latest":
        return (
          <React.Fragment>
            {currentCategory && (
              <LazyScroll
                dataLength={currentCategory.latest.data.length}
                next={() =>
                  dispatch(
                    loadLatestCategoryFeed(
                      currentCategory.latest.data.length,
                      currentCategory.value
                    )
                  )
                }
                hasMore={currentCategory.latest.hasMore}
              >
                <ImageGallery />
              </LazyScroll>
            )}
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  const seoContent = () => {
    return (
      <Helmet>
        <title>
          {process.env.REACT_APP_APP_NAME}
          {" - " + currentCategory.name}
        </title>

        {/* PRIMARY */}
        <meta
          name="title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + currentCategory.name
          }`}
        />

        {/* Open Graph */}
        <meta
          property="og:title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + currentCategory.name
          }`}
        />
        {currentCategory.src && (
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${currentCategory.src}`}
          />
        )}

        {/* Twitter */}
        <meta
          property="twitter:title"
          content={`${process.env.REACT_APP_APP_NAME}${
            " - " + currentCategory.name
          }`}
        />
        {currentCategory.src && (
          <meta
            property="twitter:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${currentCategory.src}`}
          />
        )}
      </Helmet>
    );
  };

  return (
    <React.Fragment>
      {currentCategory && seoContent()}
      {renderActiveTab()}
    </React.Fragment>
  );
};

export default CategoryGallery;
