const initialData = {
  avatar: null,
  wallpapers: 0,
  followers: 0,
  following: 0,
  type: "",
};

// USER REDUCER
const user = (state = initialData, action) => {
  switch (action.type) {
    // INIT
    case "INIT_USER_DATA":
      return {
        ...state,
        ...action.data,
      };

    // CLEAR
    case "CLEAR_USER_DATA":
      return {
        avatar: null,
        wallpapers: 0,
        followers: 0,
        following: 0,
        type: "",
      };
    case "UPDATE_CONFIRMED_TRUE":
      return { ...state, confirmed: true };

    // Avatar
    case "UPDATE_AVATAR":
      return { ...state, avatar: action.data, updatedAt: global.Date.now() };

    // Wallpaper
    case "INCREASE_WALLPAPERS_COUNT":
      return { ...state, wallpapers: state.wallpapers + 1 };
    case "DECREASE_WALLPAPERS_COUNT":
      return {
        ...state,
        wallpapers: state.wallpapers > 0 ? state.wallpapers - 1 : 0,
      };

    // Follower
    case "INCREASE_FOLLOWERS_COUNT":
      return { ...state, followers: state.followers + 1 };

    case "DECREASE_FOLLOWERS_COUNT":
      return {
        ...state,
        followers: state.followers > 0 ? state.followers - 1 : 0,
      };

    // Following
    case "INCREASE_FOLLOWING_COUNT":
      return { ...state, following: state.following + 1 };

    case "DECREASE_FOLLOWING_COUNT":
      return {
        ...state,
        following: state.following > 0 ? state.following - 1 : 0,
      };

    default:
      return state;
  }
};

export default user;
