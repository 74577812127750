const initialPreferences = {
  isOpen: false,
  contentType: "",
  contentId: "",
  reason: "",
  description: "",
};

// CONFIG REDUCER
const report = (state = initialPreferences, action) => {
  switch (action.type) {
    case "OPEN_REPORT_MODAL":
      return {
        ...state,
        isOpen: true,
        contentType: action.payload.contentType,
        contentId: action.payload.contentId,
      };
    case "CLOSE_REPORT_MODAL":
      return {
        ...state,
        isOpen: false,
        contentType: "",
        contentId: "",
        reason: "",
        description: "",
      };
    case "TOGGLE_REPORT_MODAL":
      // return { ...state, isOpen: !state.isOpen };
      if (state.isOpen) {
        return {
          ...state,
          isOpen: false,
          contentType: "",
          contentId: "",
          reason: "",
          description: "",
        };
      } else {
        return;
        // return { ...state, isOpen: true };
      }

    default:
      return state;
  }
};

export default report;
