import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";
import SEO from "../components/Misc/SEO";
import { Button, Card, CardBody, CardTitle, Alert } from "reactstrap";
import OtpInput from "react-otp-input";
import {
  confirmConfirmationCode,
  isAuth,
  requestConfirmationCode,
  updateAuth,
} from "../services/api/auth";
import { updateConfirmedTrue } from "../actions/user";
import { useDispatch, useSelector } from "react-redux";

const Confirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirmed } = useSelector((state) => state.user);

  useEffect(() => {
    confirmed && navigate(`/`);
  }, [confirmed]);

  useEffect(() => {
    isAuth() && isAuth().confirmed && navigate(`/`);
  }, []);

  const [values, setValues] = useState({
    code: "",
    error: "",
    message: "",
  });
  const { code, error, message } = values;

  const handleChange = (value) =>
    setValues({ ...values, code: value, error: "", message: "" });

  const handleResendConfirmationCode = (e) => {
    e.preventDefault();
    requestConfirmationCode(isAuth()._id).then((data) => {
      if (data.error) {
        console.error(data.error);
        setValues({ ...values, message: "", error: data.error });
      } else {
        setValues({ ...values, message: data.message, error: "" });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!code) {
      setValues({ ...values, message: "", error: "Code is required" });
    }

    confirmConfirmationCode(isAuth()._id, { code: code }).then((data) => {
      if (data.error) {
        console.error(data.error);
        setValues({ ...values, message: "", error: data.error });
      } else {
        // confirmed = true
        // update localstorage
        let authData = isAuth();
        authData.confirmed = true;
        updateAuth(authData, () => {
          setValues({ ...values, message: data.message, error: "", code: "" });
        });

        // update redux
        dispatch(updateConfirmedTrue());
      }
    });
  };

  const showError = () => {
    return (
      <React.Fragment>
        {error && <Alert color="danger">{error}</Alert>}
      </React.Fragment>
    );
  };

  const showMessage = () => {
    return (
      <React.Fragment>
        {message && <Alert color="info">{message}</Alert>}
      </React.Fragment>
    );
  };

  return (
    <Layout>
      <SEO title="Confirm" />
      <Private>
        <div className="inner-content w-100">
          <CustomScrollbar page={true}>
            <div className="h-100 my-5 d-flex justify-content-center align-items-center text-center">
              <Card className="p-4">
                <CardTitle tag="h1" className="">
                  Confirm Your Email
                </CardTitle>
                <hr />
                <CardBody>
                  <div className="d-flex justify-content-center mb-2">
                    <OtpInput
                      value={code}
                      onChange={handleChange}
                      numInputs={5}
                      separator={<span>-</span>}
                      inputStyle="otp-input"
                      hasErrored={error}
                      errorStyle="is-invalid"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-column flex-sm-row mt-5">
                    <Button
                      color="warning"
                      className="mt-sm-0 mt-4 col-xs order-2 order-sm-1"
                      onClick={handleResendConfirmationCode}
                    >
                      Resend Confirmation Code
                    </Button>
                    <Button
                      color="primary"
                      className="mr-2 mt-sm-0 mt-4 col-xs order-1 order-sm-2"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>

                  <div className="mt-5 text-left">
                    {showError()}
                    {showMessage()}
                  </div>
                </CardBody>
              </Card>
            </div>
          </CustomScrollbar>
        </div>
      </Private>
    </Layout>
  );
};

export default Confirm;
