import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { getCookie, isAuth } from "../services/api/auth";

var token = getCookie("token");

// var socket = io(process.env.REACT_APP_WS, {
//   query: { token: token },
// });

// var socket = token
//   ? io(process.env.REACT_APP_WS, {
//       query: { token: token },
//     })
//   : undefined;
var socket;

// const socket = io();

export default function useSocket(cb) {
  const [activeSocket, setActiveSocket] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // debug("Socket updated", { socket, activeSocket });
    console.log(socket);
    if (activeSocket || !socket) return;
    cb && cb(socket);
    setActiveSocket(socket);
    return function cleanup() {
      // console.log("Cleaning ", socket);
      // debug("Running useSocket cleanup", { socket });
      // socket.off("message.chat1", cb);
      // socket.off(socket.id, cb);
    };
  }, [socket]);

  useEffect(() => {
    if (socket && !user.username) {
      // Connected + No Username = Disconnect
      // alert("Disconnecting WS");
      socket.disconnect();
      socket = undefined;
    } else {
      token = getCookie("token");
      if (token && !socket) {
        // alert("Connecting WS");
        socket = io(process.env.REACT_APP_WS, {
          query: { token: token },
          transports: ["websocket", "polling"], // use WebSocket first, if available
        });
      }
    }
  }, [user]);

  return activeSocket;
}
