import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { FiPlusSquare } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";

const CreateButton = () => {
  const location = useLocation();
  let isCollapsed = useMediaQuery({ query: "(max-width: 768px)" }); // same as collapse in navbar Boostrap4 768px

  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    setIsFloating(isCollapsed);
  }, [isCollapsed]);

  return isFloating && location.pathname === "/create" ? null : isFloating ? (
    <div className="float-button-container">
      <Link to="/create">
        <Button
          color="primary"
          className="br-50 d-flex"
          style={{ height: "75px", width: "75px" }}
        >
          <FiPlusSquare className="m-auto" color="#ffffff" size={30} />
        </Button>
      </Link>
    </div>
  ) : (
    <Link to="/create">
      <Button color="primary" className="br-2 mx-3 my-auto px-4 d-flex">
        <FiPlusSquare className="my-auto mr-2" color="#ffffff" size={20} />
        <span className="my-auto"> Create</span>
      </Button>
    </Link>
  );
};

export default CreateButton;
