import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import SEO from "../components/Misc/SEO";
import SearchGallery from "../components/Search";

const Search = () => {
  const { string } = useParams();

  useEffect(() => {}, [string]);

  return (
    <Layout>
      <SEO title="Search" hasDynamicContent />
      <div className="inner-content w-100">
        <CustomScrollbar page={true}>
          <SearchGallery queryString={string} />
        </CustomScrollbar>
      </div>
    </Layout>
  );
};

export default Search;
