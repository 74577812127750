import React from "react";
import NotConfirmedAlert from "./NotConfirmedAlert";
import NoUsernameAlert from "./NoUsernameAlert";

const Notice = () => {
  return (
    <div>
      <NoUsernameAlert />
      <NotConfirmedAlert />
    </div>
  );
};

export default Notice;
