const initialPreferences = {
  darkMode: false,
  loggedin: false,
};

// CONFIG REDUCER
const config = (state = initialPreferences, action) => {
  switch (action.type) {
    case "TOGGLE_THEME":
      return { ...state, darkMode: !state.darkMode };
    case "LOG_IN":
      return { ...state, loggedin: true };
    case "LOG_OUT":
      return { ...state, loggedin: false };

    default:
      return state;
  }
};

export default config;
