const initialFeed = {
  hot: {
    daily: {
      data: [],
      count: 0,
      hasMore: true,
    },
    weekly: {
      data: [],
      count: 0,
      hasMore: true,
    },
    monthly: {
      data: [],
      count: 0,
      hasMore: true,
    },
    all: {
      data: [],
      count: 0,
      hasMore: true,
    },
  },
  latest: {
    data: [],
    count: 0,
    hasMore: true,
  },
  timeline: {
    data: [],
    count: 0,
    hasMore: true,
  },
};

// FEED REDUCER
const feed = (state = initialFeed, action) => {
  switch (action.type) {
    case "LOAD_HOT_FEED":
      return {
        ...state,
        hot: {
          ...state.hot,
          [action.payload.timeframe]: {
            ...state.hot[action.payload.timeframe],
            data: [
              ...state.hot[action.payload.timeframe].data,
              ...action.payload.data,
            ],
            count: action.payload.count,
            hasMore:
              [
                ...state.hot[action.payload.timeframe].data,
                ...action.payload.data,
              ].length < action.payload.count
                ? true
                : false,
          },
        },
      };

    // if (action.payload.timeframe === "all") {
    //   state = {
    //     ...state,
    //     hot: {
    //       ...state.hot,
    //       all: {
    //         ...state.hot.all,
    //         data: [...state.hot.all.data, ...action.payload.data],
    //         count: action.payload.count,
    //         hasMore:
    //           [...state.hot.all.data, ...action.payload.data].length <
    //           action.payload.count
    //             ? true
    //             : false,
    //       },
    //     },
    //   };

    //   // state.hot.all = {
    //   //   ...state.hot.all,
    //   //   data: [...state.hot.all, ...action.payload.data],
    //   //   hasMore: action.payload.hasMore,
    //   // };
    // }
    // return state;
    case "LOAD_LATEST_FEED":
      return {
        ...state,
        latest: {
          data: [...state.latest.data, ...action.payload.data],
          count: action.payload.count,
          hasMore:
            [...state.latest.data, ...action.payload.data].length <
            action.payload.count
              ? true
              : false,
        },
      };
    case "LOAD_TIMELINE_FEED":
      return {
        ...state,
        timeline: {
          data: [...state.timeline.data, ...action.payload.data],
          count: action.payload.count,
          hasMore:
            [...state.timeline.data, ...action.payload.data].length <
            action.payload.count
              ? true
              : false,
        },
      };
    default:
      return state;
  }
};

export default feed;

// TODO Research - Nested Redux Reducer
// PUSH DATA
