import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";

import CustomScrollbar from "../components/Misc/CustomScrollbar";
import SEO from "../components/Misc/SEO";
import ProfileComponent from "../components/Profile";

const Profile = () => {
  const { username } = useParams();

  return (
    <div>
      <Layout>
        <SEO title="Profile" hasDynamicContent />
        <div className="inner-content w-100 profile-page">
          <CustomScrollbar page={true}>
            {username && <ProfileComponent username={username} />}
          </CustomScrollbar>
        </div>{" "}
      </Layout>
    </div>
  );
};

export default Profile;
