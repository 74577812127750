import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import SidebarContent from "../Sidebar";
import Sidebar from "react-sidebar";
import { useMediaQuery } from "react-responsive";

const Layout = ({ children, className, innerClassName }) => {
  const [sidebarDocked, setsidebarDocked] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebarOpen = () => setSidebarOpen(!sidebarOpen);

  let isLaptopOrDesktop = useMediaQuery({ query: "(min-width: 1025px)" });
  let isMobileOrTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    setsidebarDocked(isLaptopOrDesktop);
    setSidebarOpen(false);
  }, [isLaptopOrDesktop]);

  return (
    <div
      className={`d-flex vh-100 overflow-hidden ${className && className}`}
      id="wrapper"
    >
      {/* <Sidebar /> */}

      <Sidebar
        sidebarClassName="sidebar-parent"
        sidebar={<SidebarContent closeSidebar={() => setSidebarOpen(false)} />}
        open={sidebarOpen}
        docked={sidebarDocked}
        onSetOpen={(value) => setSidebarOpen(value)}
        touch
        // id="sidebar-wrapper"
        // style={{ boxShadow: "0px 0px 10px #0000000d !important" }}
        // styles={{ sidebar: { boxShadow: "0px 0px 10px #0000000d !important" } }}
        shadow={false}
        transitions={isMobileOrTablet ? true : false}
      >
        {/* <b>Main content</b> */}
        <div id="page-content-wrapper" className="">
          <div className="custom-header">
            <div
              className={`container-fluid ${!isMobileOrTablet ? "px-5" : ""}`}
              // style={{ maxWidth: "1200px" }}
            >
              <NavBar toggleSidebarOpen={toggleSidebarOpen} />
            </div>
          </div>

          <div
            className={`inner-content w-100 ${
              innerClassName && innerClassName
            }`}
          >
            {/* {isAuth() && !isAuth().username && (
              <div className="mx-5 my-4">
                <Alert
                  color="warning"
                  className="d-flex justify-content-between"
                >
                  <span className="my-auto">
                    Please create your username to use all features
                  </span>
                  <Link to={`/settings`}>
                    <button className="btn btn-warning btn-sm">
                      Go To Settings
                    </button>
                  </Link>
                </Alert>
              </div>
            )} */}

            {children}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default Layout;
