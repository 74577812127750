import {
  retrieveHotFeed,
  retrieveLatestFeed,
  retrieveTimelineFeed,
} from "../services/api/feed";
import { getCookie } from "../services/api/auth";

export const loadHotFeed =
  (offset, timeframe = "all") =>
  async (dispatch) => {
    const wallpapers = await retrieveHotFeed(offset, timeframe).then((data) => {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    });

    return dispatch({
      type: "LOAD_HOT_FEED",
      payload: {
        timeframe: timeframe ? timeframe : "all",
        data: wallpapers.data,
        count: wallpapers.count,
      },
    });
  };

export const loadLatestFeed = (offset) => async (dispatch) => {
  const wallpapers = await retrieveLatestFeed(offset).then((data) => {
    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  return dispatch({
    type: "LOAD_LATEST_FEED",
    payload: {
      data: wallpapers.data,
      // hasMore: finalData.length > 0 ? true : false,
      count: wallpapers.count,
    },
  });
};

export const loadTimelineFeed = (offset) => async (dispatch) => {
  const token = getCookie("token");

  const wallpapers = await retrieveTimelineFeed(offset, token).then((data) => {
    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  return dispatch({
    type: "LOAD_TIMELINE_FEED",
    payload: {
      data: wallpapers.data,
      // hasMore: finalData.length > 0 ? true : false,
      count: wallpapers.count,
    },
  });
};
