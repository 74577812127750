import React from "react";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";
import UnderDevelopment from "../components/Misc/UnderDevelopment";
import SEO from "../components/Misc/SEO";

const advertise = () => {
  return (
    <Layout>
      <SEO title="Advertise" />
      <Private>
        <div className="inner-content w-100">
          <CustomScrollbar page={true}>
            <UnderDevelopment />
          </CustomScrollbar>
        </div>
      </Private>
    </Layout>
  );
};

export default advertise;
