import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ToggleTheme from "../Misc/ToggleTheme";

const Header = ({ buttonType }) => {
  const navigate = useNavigate();
  return (
    <div className="px-5 py-4">
      <nav className="navbar justify-content-between">
        <Link to="/">
          <a className="navbar-brand mr-0">{process.env.REACT_APP_APP_NAME}.</a>
        </Link>

        <div className="d-flex">
          {buttonType === "Signup" ? (
            <button
              className="btn btn-primary my-2 my-sm-0 px-5 br-2 mx-3"
              type="submit"
              onClick={() => navigate("/signup")}
            >
              Signup
            </button>
          ) : buttonType === "Login" ? (
            <button
              className="btn btn-primary my-2 my-sm-0 px-5 br-2 mx-3"
              type="submit"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
          ) : null}

          <ToggleTheme />
        </div>
      </nav>
    </div>
  );
};

export default Header;
