export const toggleReportModal = () => (dispatch) =>
  dispatch({
    type: "TOGGLE_REPORT_MODAL",
  });

export const openReportModal =
  ({ contentType, contentId }) =>
  (dispatch) =>
    dispatch({
      type: "OPEN_REPORT_MODAL",
      payload: { contentType, contentId },
    });

export const closeReportModal = () => (dispatch) =>
  dispatch({
    type: "CLOSE_REPORT_MODAL",
  });
