import React, { useEffect, useState } from "react";
import { FiMoon, FiSun } from "react-icons/fi";
import { toggleTheme } from "../../actions/config";
import { useSelector, useDispatch } from "react-redux";

const ToggleTheme = () => {
  const darkMode = useSelector((state) => state.config.darkMode);
  const { type } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [userType, setUserType] = useState(type ? type : "");

  useEffect(() => {
    setUserType(type);
  }, [type]);

  const handleToggle = (e) => {
    e.preventDefault();

    if (userType && userType === "pro") {
      dispatch(toggleTheme());
    }
  };

  return userType && userType === "pro" ? (
    <div className="dark-mode-toggle my-auto d-flex justify-content-between">
      <button type="button" onClick={handleToggle}>
        {darkMode ? <FiSun size={20} color="#ffe600" /> : <FiMoon size={20} />}
      </button>
    </div>
  ) : null;
};

export default ToggleTheme;
