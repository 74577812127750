import { getCookie } from "../services/api/auth";
import { retrieveUser } from "../services/api/user";

export const initUserData =
  (username, token = getCookie("token")) =>
  async (dispatch) => {
    const userData = await retrieveUser(username, token).then((data) => {
      if (!data) throw Error("API Server didn't respond");

      if (data.error) {
        console.error(data.error);
        return null;
      } else {
        return data;
      }
    });

    if (userData) {
      const dataToSet = {
        avatar: userData.avatar,
        wallpapers: userData.wallpapers,
        following: userData.following,
        followers: userData.followers,
        updatedAt: userData.user.updatedAt,
        email: userData.user.email,
        username: userData.user.username,
        fullName: userData.user.fullName,
        verified: userData.user.verified,
        confirmed: userData.user.confirmed,
        role: userData.user.role,
        type: userData.user.type,
      };

      // alert(dataToSet.username, "is set");

      return dispatch({
        type: "INIT_USER_DATA",
        data: dataToSet,
      });
    }
  };

export const clearUserData = () => async (dispatch) => {
  return dispatch({
    type: "CLEAR_USER_DATA",
  });
};

// Confirm User
export const updateConfirmedTrue = () => (dispatch) => {
  return dispatch({
    type: "UPDATE_CONFIRMED_TRUE",
  });
};

// AVATAR
export const updateAvatar = (url) => (dispatch) => {
  let avatar = url + `?${global.Date.now()}`;

  return dispatch({
    type: "UPDATE_AVATAR",
    data: avatar,
  });
};

// WALLPAPER
export const increaseWallpapersCount = () => (dispatch) => {
  return dispatch({
    type: "INCREASE_WALLPAPERS_COUNT",
  });
};

export const decreaseWallpapersCount = () => (dispatch) => {
  return dispatch({
    type: "DECREASE_WALLPAPERS_COUNT",
  });
};

// FOLLOWERS
export const increaseFollowersCount = () => (dispatch) => {
  return dispatch({
    type: "INCREASE_FOLLOWERS_COUNT",
  });
};

export const decreaseFollowersCount = () => (dispatch) => {
  return dispatch({
    type: "DECREASE_FOLLOWERS_COUNT",
  });
};

// FOLLOWING

export const increaseFollowingCount = () => (dispatch) => {
  return dispatch({
    type: "INCREASE_FOLLOWING_COUNT",
  });
};
export const decreaseFollowingCount = () => (dispatch) => {
  return dispatch({
    type: "DECREASE_FOLLOWING_COUNT",
  });
};
