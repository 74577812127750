import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const TagInput = ({ label, description, value, maxTags, handleTags }) => {
  const [values, setValues] = useState({
    inputValue: "",
    error: "",
  });
  const { inputValue, error } = values;
  const pasteSplit = (data) => {
    const separators = [
      " ",
      ",",
      ";",
      "\\(",
      "\\)",
      "\\*",
      "/",
      ":",
      "\\?",
      "\n",
      "\r",
    ];
    return data.split(new RegExp(separators.join("|"))).map((d) => d.trim());
  };

  return (
    <div className="form-group">
      {error && <div className="mt-3 alert alert-danger">{error}</div>}
      <div className="d-flex flex-column">
        <label>{label}</label>
        <TagsInput
          value={value}
          onChange={handleTags}
          className="bootstrap-tagsinput"
          maxTags={maxTags}
          inputValue={inputValue}
          onChangeInput={(e) => {
            if (inputValue.length < 50)
              setValues({ ...values, error: "", inputValue: e.toLowerCase() });
          }}
          onlyUnique
          addOnPaste={true}
          pasteSplit={pasteSplit}
          validationRegex={/^[a-z]+$/}
          onValidationReject={(e) =>
            setValues({ ...values, error: "Only lower case alphabets allowed" })
          }
          tagProps={{
            className: "tag react-tagsinput-tag",
          }}
        />
        {description && <p className="m-2">{description}</p>}
        {maxTags && (
          <p className="text-muted text-sm m-2 ml-auto">
            *Max Tags - {maxTags}
          </p>
        )}
      </div>
    </div>
  );
};

export default TagInput;
