import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
const API = process.env.REACT_APP_API;

/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */

export const handleResponse = (
  response,
  message = "Your session is expired. Please login"
) => {
  if (response.status === 401) {
    signout(() => {
      window.location.href = "/login";
    });
  }
};

// Set Cookie
export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 365,
    });
  }
};

// Get Cookie
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

// Remove Cookie
export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key);
  }
};

// Set LocalStorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// Remove LocalStorage
export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key);
  }
};

// autheticate user by pass data to cookie and localstorage
export const authenticate = (data, next) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  next();
};

export const updateAuth = (user, next) => {
  if (process.browser) {
    if (localStorage.getItem("user")) {
      let auth = JSON.parse(localStorage.getItem("user"));
      auth = user;
      localStorage.setItem("user", JSON.stringify(auth));
      next();
    }
  }
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    }
  }
};

/* -------------------------------------------------------------------------- */
/*                                 API Methods                                */
/* -------------------------------------------------------------------------- */

export const signupUser = (data) => {
  return fetch(`${API}/auth/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signout = (next) => {
  removeCookie("token");
  removeLocalStorage("user");
  next();

  return fetch(`${API}/signout`, {
    method: "GET",
  })
    .then((response) => {
      console.log("signout success");
    })
    .catch((err) => console.log(err));
};

export const loginUser = (data) => {
  return fetch(`${API}/auth/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// export const forgotPassword = email => {
//   return fetch(`${API}/forgot-password`, {
//       method: 'PUT',
//       headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(email)
//   })
//       .then(response => {
//           return response.json();
//       })
//       .catch(err => console.log(err));
// };

// export const resetPassword = resetInfo => {
//   return fetch(`${API}/reset-password`, {
//       method: 'PUT',
//       headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(resetInfo)
//   })
//       .then(response => {
//           return response.json();
//       })
//       .catch(err => console.log(err));
// };

export const requestConfirmationCode = (id) => {
  const token = getCookie("token");
  return fetch(`${API}/auth/confirm/${id}/request`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const confirmConfirmationCode = (id, data) => {
  const token = getCookie("token");
  return fetch(`${API}/auth/confirm/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const loginWithGoogleOauth = (data) => {
  return fetch(`${API}/auth/google-login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
