import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { loadCategories } from "../../actions/category";
import { fetchNotifications } from "../../actions/notification";
import { initUserData } from "../../actions/user";
import { getCookie, isAuth } from "../../services/api/auth";
import { retrieveUser } from "../../services/api/user";
import ModalUrlHandler from "./ModalUrlHandler";
import ThemeHandler from "./ThemeHandler";

const AppInitHandler = () => {
  const dispatch = useDispatch();
  const token = getCookie("token");

  /* -------------------------------- ONE TIME -------------------------------- */

  useEffect(() => {
    // Load User, Categories, Notifications
    getUser();
    dispatch(loadCategories());

    // notifications can be a delay
    setTimeout(() => {
      if (isAuth() && isAuth().username) {
        dispatch(fetchNotifications(0)); // offset
      }
    }, 2000);
  }, []);

  const getUser = async () => {
    if (isAuth() && isAuth().username) {
      dispatch(initUserData(isAuth().username));
    }
  };

  return (
    <React.Fragment>
      <ThemeHandler />
      <ModalUrlHandler />
    </React.Fragment>
  );
};

export default AppInitHandler;
