const initialPreferences = {
  data: [],
  activeTab: "hot",
  timeframe: "all",
};

// CONFIG REDUCER
const gallery = (state = initialPreferences, action) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload.index };
    case "SET_TIMEFRAME":
      return { ...state, timeframe: action.payload.timeframe };
    case "SET_GALLERY_DATA":
      return { ...state, data: action.payload.data };
    case "GALLERY_REMOVE_SINGLE_ITEM":
      return {
        ...state,
        data: state.data.filter((d) => d._id !== action.payload.wallpaperId),
      };
    case "ADD_GALLERY_DATA":
      return { ...state, data: [...state.data, ...action.payload.data] };
    default:
      return state;
  }
};

export default gallery;
