import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap";
import {
  FaEye,
  FaEyeSlash,
  FaArrowRight,
  FaRegCircle,
  FaRegCheckCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import Header from "../Header";

import { signupUser, authenticate, isAuth } from "../../services/api/auth";
import SocialLogin from "../Auth/SocialLogin";
import { useDispatch } from "react-redux";
import { initUserData } from "../../actions/user";

const SignupComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    username: "",
    password: "",
    localErrors: {
      email: "",
      username: "",
      password: "",
    },
    error: "",
    loading: false,
    message: "",
  });
  const { email, username, password, localErrors, error, message, loading } =
    values;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordVisible(passwordVisible ? false : true);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
      localErrors: {
        email: "",
        username: "",
        password: "",
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate
    if (!email) {
      setValues({
        ...values,
        localErrors: { ...localErrors, email: "Email is Required" },
      });
      return;
    } else if (!username) {
      setValues({
        ...values,
        localErrors: { ...localErrors, username: "Username is Required" },
      });
      return;
    } else if (!password) {
      setValues({
        ...values,
        localErrors: { ...localErrors, password: "Password is Required" },
      });
      return;
    } else {
      setValues({ ...values, loading: true });
      // wallpaper to backend
      const user = { username, email, password };
      signupUser(user).then((data) => {
        if (data.error) {
          if (data.errorField) {
            setValues({
              ...values,
              localErrors: { ...localErrors, [data.errorField]: data.error },
              loading: false,
            });
          } else {
            setValues({
              ...values,
              error: data.error,
              loading: false,
            });
          }
        } else {
          setValues({
            ...values,
            username: "",
            email: "",
            password: "",
            localErrors: {
              email: "",
              username: "",
              password: "",
            },
            loading: false,
            message: "",
            showForm: false,
          });

          // save user token to cookie
          // save user info to localstorage
          // authenticate user
          authenticate(data, () => {
            if (data && data.user && data.user.username) {
              dispatch(initUserData(data.user.username)).then(() => {
                navigate(`/`);
              });
            }
          });
        }
      });
    }
  };

  const formContent = () => {
    return (
      <React.Fragment>
        {loading ? (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="text-left">
            {error && <div className="mt-3 alert alert-danger">{error}</div>}

            <Form onSubmit={handleSubmit}>
              <FormGroup className="mt-3 br-2">
                <Input
                  className="input-big"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  invalid={localErrors.email}
                />

                {localErrors.email && (
                  <FormFeedback> {localErrors.email}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="mt-3 br-2">
                <InputGroup>
                  <Input
                    className="input-big"
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={(e) => handleChange("username", e.target.value)}
                    invalid={localErrors.username}
                    maxLength={32}
                    // valid
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="mr-3 d-flex">
                        {username ? (
                          localErrors.username ? (
                            <FaRegTimesCircle size={24} color="#e34b4b" />
                          ) : (
                            <FaRegCheckCircle size={24} color="#5fe34b" />
                          )
                        ) : (
                          <FaRegCircle size={24} />
                        )}
                      </i>
                    </InputGroupText>
                  </InputGroupAddon>
                  {/* <FormFeedback valid>looks good</FormFeedback> */}
                  {localErrors.username && (
                    <FormFeedback> {localErrors.username}</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>
              <FormGroup className="mt-3 br-2 pass-wrapper">
                <InputGroup>
                  <Input
                    className="input-big"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    invalid={localErrors.password}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        onClick={togglePasswordVisiblity}
                        className="mr-3 d-flex"
                      >
                        {passwordVisible ? (
                          <FaEyeSlash size={24} />
                        ) : (
                          <FaEye size={24} />
                        )}
                      </i>
                    </InputGroupText>
                  </InputGroupAddon>
                  {localErrors.password && (
                    <FormFeedback> {localErrors.password}</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>
              {/* <FormGroup check>
                <Label check>
                  <Input type="checkbox" /> I agree to Privacy Policy and Terms
                  Of Service
                </Label>
              </FormGroup> */}
              {/* <p className="text-sm">
                By clicking signup, I agree to Privacy Policy and TOS
              </p> */}
              <button
                style={{ height: "60px" }}
                className="btn btn-block btn-primary br-2 mt-3 d-flex align-items-center"
              >
                <h5 className="mb-0 mr-auto">Signup</h5>
                <FaArrowRight size={24} />
              </button>
            </Form>
          </div>
        )}
      </React.Fragment>
    );
  };

  const pageContent = () => {
    return (
      <div className="h-100">
        <div
          className="container my-3 text-center d-flex flex-column justify-content-around h-100"
          // style={{ minHeight: "700px" }}
          style={{ minHeight: "75vh" }}
        >
          <div className="px-5">
            <h1 className="">Register On {process.env.REACT_APP_APP_NAME}</h1>
            <h3 className="mt-3 text-muted">
              Join the Wallpaper Social Network <br /> Share something awesome
              with everyone
            </h3>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-lg-5 col-xl-5">{formContent()}</div>
            <div className="col-0 col-lg-1 col-xl-1 align-self-center slash"></div>
            <div className="col-12 col-lg-5 col-xl-5">
              <SocialLogin />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Header buttonType="Login" /> {pageContent()}
    </React.Fragment>
  );
};

export default SignupComponent;
