import React, { useState } from "react";
import Avatar from "../Avatar";
import { FiEdit } from "react-icons/fi";
import { getCookie, isAuth, updateAuth } from "../../services/api/auth";
import { updateUserAvatar } from "../../services/api/user";
import { useSelector, useDispatch } from "react-redux";
import { updateAvatar } from "../../actions/user";
import { Spinner } from "reactstrap";

const AvatarEdit = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    error: false,
    success: false,
    loading: false,
  });

  const token = getCookie("token");
  const { error, success, loading } = values;

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const handleChange = (e) => {
    if (!e.target.files[0]) {
      console.log("No File Select");
      return;
    }
    // e.preventDefault();
    setValues({ ...values, loading: true, error: false, success: false });

    let userFormData = new FormData();
    userFormData.set("avatar", e.target.files[0]);

    updateUserAvatar(userFormData, token).then((data) => {
      if (data.error) {
        setValues({
          ...values,
          error: data.error,
          success: false,
          loading: false,
        });
      } else {
        // redux
        dispatch(updateAvatar(data.avatar));

        // data.avatar = data.avatar + `?${global.Date.now()}`;

        // localstorage
        updateAuth(data, () => {
          // done
          setValues({
            ...values,
            error: false,
            success: true,
            loading: false,
          });
        });
      }
    });

    // setValues({ ...values, error: false, success: false });
  };

  const pageContent = () => {
    return (
      <div className="avatar-upload">
        <div
          className="avatar-edit"
          style={{
            display: loading ? "none" : "",
          }}
        >
          <input
            onChange={handleChange}
            type="file"
            id="imageUpload"
            accept=".png, .jpg, .jpeg .gif"
          />
          <label htmlFor="imageUpload">
            <FiEdit className="m-2" color="#7d7d7d" size={20} />
          </label>
        </div>
        <div className="avatar-preview">
          <div id="clock">
            <div
              className="w-50 h-50"
              style={{
                position: "absolute",
                margin: "auto",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                textAlign: "center",
                zIndex: "1",
                display: loading ? "" : "none",
              }}
            >
              <Spinner color="primary" size={"lg"} />
            </div>

            <Avatar
              width={"100%"}
              height={"100%"}
              opacity={loading ? 0.25 : 1}
              self
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div>{pageContent()}</div>
      {loading}
      <div className="w-100">{showError()}</div>
    </div>
  );
};

export default AvatarEdit;
