import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTransition } from "react-spring";
import { useMediaQuery } from "react-responsive";

import NotificationPopup from "./NotificationPopup";
import { FiActivity } from "react-icons/fi";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NotificationDropdownMenu from "./NotificationDropdownMenu";

const NotificationButton = () => {
  let isMobileOrTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  const { data: notifications, unreadCount } = useSelector(
    (state) => state.notification
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationPopupTimeout, setShowNotificationPopupTimeout] =
    useState(null);

  useEffect(() => {
    if (notificationPopupTimeout) {
      clearTimeout(notificationPopupTimeout);
    }
    if (unreadCount > 0) {
      !showNotificationPopup && setShowNotificationPopup(true);
      setShowNotificationPopupTimeout(
        setTimeout(() => setShowNotificationPopup(false), 10000)
      );
    }
  }, [unreadCount]);

  useEffect(() => {
    if (showNotifications) {
      clearTimeout(notificationPopupTimeout);
      setShowNotificationPopup(false);
    }
  }, [showNotifications, notificationPopupTimeout]);

  const transitions = useTransition(
    unreadCount > 0 && showNotificationPopup ? { notifications } : false,
    null,
    {
      from: {
        transform: "scale(0) translateX(-50%)",
        opacity: 0,
      },
      enter: {
        transform: "scale(1) translateX(-50%)",
        opacity: 1,
      },
      leave: {
        transform: "scale(0) translateX(-50%)",
        opacity: 0,
      },
      config: {
        tension: 280,
        friction: 20,
      },
    }
  );

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <React.Fragment>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          caret={false}
          tag="button"
          className="notification-button"
        >
          {/* <button className="notification-button"> */}
          <FiActivity
            className="my-auto mx-2"
            color="#7d7d7d"
            size={24}
            onClick={() =>
              !isMobileOrTablet && setShowNotifications((previous) => !previous)
            }
          />
          {/* </button> */}

          {unreadCount > 0 && (
            <span
              className="badge badge-danger p-0"
              style={{
                position: "absolute",
                bottom: "15%",
                right: "15%",
                width: "7px",
                height: "7px",
              }}
            >{` `}</span>
          )}

          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <NotificationPopup
                  style={props}
                  notifications={item.notifications}
                  key={key}
                />
              )
          )}
        </DropdownToggle>

        <DropdownMenu
          right
          className="p-0 overflow-hidden border-0 notification-menu"
        >
          <NotificationDropdownMenu />
        </DropdownMenu>

        {/* {!isMobileOrTablet && (
          <DropdownMenu
            right
            className="p-0 br-2 overflow-hidden border-0 notification-menu bg-dark"
          >
            <NotificationDropdownMenu />
          </DropdownMenu>
        )} */}
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationButton;
