import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomScrollbar from "../../../../Misc/CustomScrollbar";
import SelectOption from "../../../../Misc/SelectOption";
import TagInput from "../../../../Inputs/TagInput";
import { editWallpaper, setEditing } from "../../../../../actions/lightbox";
import { getCategoryById } from "../../../../../utils/category";

const EditRender = () => {
  const dispatch = useDispatch();

  const availableCategories = useSelector((state) => state.categories);
  const { currentWallpaper: wallpaper, editing } = useSelector(
    (state) => state.lightbox
  );

  const [values, setValues] = useState({
    description: "",
    category: null, // {}
    tags: [],
  });
  const { description, category, tags } = values;

  useEffect(() => {
    updateValues();
  }, [availableCategories]);

  useEffect(() => {
    updateValues();
  }, [editing]);

  const updateValues = () => {
    if (editing) {
      setValues({
        ...values,
        category: getCategoryById(wallpaper.category, availableCategories),
        description: wallpaper.description,
        tags: wallpaper.tags,
      });
    }
  };

  const handleCategory = (category) => {
    setValues({ ...values, category });
  };

  const handleTags = (tags) => {
    setValues({ ...values, tags }); // toLowerCase
  };

  return (
    <React.Fragment>
      <div className="border-bottom p-3">
        <h5 className="display-5 mb-0">Edit Post</h5>
      </div>

      <div className="h-100">
        <CustomScrollbar>
          <div className="p-3">
            <div className="">
              <div className="d-flex justify-content-between">
                <label>Description</label>

                {description && (
                  <span
                    className={`my-auto badge ${
                      description.length >= 200
                        ? "badge-danger"
                        : "badge-primary"
                    }`}
                  >
                    {description.length} / 200
                  </span>
                )}
              </div>

              <textarea
                className="w-100 p-2"
                rows="5"
                value={description}
                maxLength={200}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
              ></textarea>
            </div>

            <div className="mt-3">
              <SelectOption
                label="Select Category"
                value={category}
                options={availableCategories}
                onSelect={handleCategory}
              />
            </div>

            <div className="mt-3">
              <TagInput
                label="Edit Tags"
                maxTags={10}
                value={tags}
                handleTags={handleTags}
              />
            </div>
          </div>
        </CustomScrollbar>
      </div>

      <div className="mt-auto p-3">
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              dispatch(
                editWallpaper(wallpaper._id, {
                  description: description,
                  category: category.value,
                  tags: tags.join(),
                })
              )
            }
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-danger h-auto"
            onClick={() => dispatch(setEditing(false))}
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditRender;
