import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import UserCard from "../UserCard";
import { getCookie, isAuth } from "../../services/api/auth";
import { retrieveFollowers } from "../../services/api/user";
import LazyScroll from "../Misc/LazyScroll";
import CustomScrollbar from "../Misc/CustomScrollbar";
import millify from "millify";

const FollowersModal = ({ followersCount, userId }) => {
  const prevUserID = usePrevious(userId);
  const token = getCookie("token");
  // const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [following, setFollowing] = useState([]);

  useEffect(() => {
    // retrieve Followers
    getFollowers();

    // add to array
  }, [userId]);

  const getFollowers = async () => {
    await retrieveFollowers(userId, token, following.length)
      .then((data) => {
        if (data.error) {
          console.error(data.error);
        } else {
          if (prevUserID === userId) {
            setFollowing([...following, ...data]);
          } else {
            setFollowing(data);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="follow-data-modal"
        centered
      >
        <ModalHeader toggle={toggle}>Followers</ModalHeader>
        <ModalBody>
          <div className="m-3" style={{ height: "calc(30vh)" }}>
            <CustomScrollbar>
              {following &&
                following.map((user) => {
                  return (
                    <UserCard
                      key={user.username}
                      avatar={user.avatar}
                      username={user.username}
                      href={user.username && `/profile/${user.username}`}
                      // onClick={() => {
                      //   navigate(`/profile/${user.username}`);
                      //   setModal(false);
                      // }}
                    />
                  );
                })}

              <div className="my-4 d-flex justify-content-center">
                <button
                  className={`btn btn-secondary ${
                    !(following.length < followersCount) && "d-none"
                  }`}
                  // disabled={!comments.hasMore}
                  onClick={getFollowers}
                >
                  Load More
                </button>
              </div>
            </CustomScrollbar>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button color='primary' onClick={toggle}>
            Do Something
          </Button>{" "}
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>

      <h5 className="mr-5" onClick={toggle} style={{ cursor: "pointer" }}>
        <span className="text-muted">{millify(followersCount)}</span> Followers
      </h5>
    </React.Fragment>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export default FollowersModal;
