import React from "react";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";
import SEO from "../components/Misc/SEO";

const pro = () => {
  return (
    <Layout>
      <SEO title="Pro" />
      <Private>
        <div className="inner-content w-100">
          <CustomScrollbar page={true}>
            <h1>pro</h1>
          </CustomScrollbar>
        </div>
      </Private>
    </Layout>
  );
};

export default pro;
