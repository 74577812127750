import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const retrieveCategories = () => {
  return fetch(`${API}/category`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
