import { combineReducers } from "redux";

import configReducer from "./config";
import userReducer from "./user";
import bookmarkReducer from "./bookmark";
import categoryReducer from "./category";
import feedReducer from "./feed";
import lightboxReducer from "./lightbox";
import galleryReducer from "./gallery";
import notificationReducer from "./notification";
import reportReducer from "./report";

// COMBINED REDUCERS
const reducers = {
  config: configReducer,
  bookmark: bookmarkReducer,
  user: userReducer,
  categories: categoryReducer,
  feed: feedReducer,
  notification: notificationReducer,
  lightbox: lightboxReducer,
  gallery: galleryReducer,
  report: reportReducer,
};

export default combineReducers(reducers);
