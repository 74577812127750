import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { setMediaReady } from "../../../../actions/lightbox";

const LightboxMedia = () => {
  const dispatch = useDispatch();
  const { currentWallpaper: wallpaper, mediaReady } = useSelector(
    (state) => state.lightbox
  );

  const LoadingSkeleton = (props) => (
    <div className="h-100 w-100">
      <ShimmerThumbnail fitOnFrame={true} className="m-0" />
    </div>
  );

  return (
    <React.Fragment>
      <div className="wallpaper-container flex-1">
        {/* {MyLoader()} */}

        <div className="wallpaper-container-inner h-100">
          {mediaReady ? null : LoadingSkeleton()}

          {wallpaper && (
            <img
              src={`${process.env.REACT_APP_S3_BUCKET}/${
                wallpaper.content.preview
                  ? wallpaper.content.preview
                  : wallpaper.content.original
              }`}
              alt={wallpaper.title}
              className="w-100 h-100 wallpaper-image"
              style={{
                maxHeight: "90vh",
                display: mediaReady ? "block" : "none",
              }}
              onLoad={() => dispatch(setMediaReady(true))}
              onLoadStart={() => console.log("Start")}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${process.env.REACT_APP_S3_BUCKET}/${wallpaper.content.original}`;
              }}
            />
          )}
          <div
            style={{
              position: "absolute",
              inset: 0,
            }}
          >
            {/* <button className="btn btn-primary btn-block">Download</button> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LightboxMedia;
