import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { loadWallpaper } from "../../../actions/lightbox";
import LightboxDetails from "./Details";
import LightboxMedia from "./Media";

const LightboxContent = ({ wallpaperId }) => {
  const dispatch = useDispatch();
  const { currentWallpaper: wallpaper, mediaReady } = useSelector(
    (state) => state.lightbox
  );

  useEffect(() => {
    dispatch(loadWallpaper(wallpaperId));
  }, [wallpaperId]);

  const seoContent = () => {
    return (
      <Helmet>
        <title>{process.env.REACT_APP_APP_NAME} - Wallpaper</title>

        {/* PRIMARY */}
        <meta
          name="title"
          content={`${process.env.REACT_APP_APP_NAME} - Wallpaper`}
        />
        {wallpaper.description && (
          <meta name="description" content={wallpaper.description} />
        )}

        {/* Open Graph */}
        <meta
          property="og:title"
          content={`${process.env.REACT_APP_APP_NAME} - Wallpaper`}
        />
        {wallpaper.description && (
          <meta property="og:description" content={wallpaper.description} />
        )}
        {wallpaper.thumbnail && (
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${wallpaper.thumbnail}`}
          />
        )}

        {/* Twitter */}
        {wallpaper.thumbnail && (
          <meta property="twitter:card" content="summary" />
        )}

        <meta
          property="twitter:title"
          content={`${process.env.REACT_APP_APP_NAME} - Wallpaper`}
        />
        {wallpaper.description && (
          <meta
            property="twitter:description"
            content={wallpaper.description}
          />
        )}
        {wallpaper.thumbnail && (
          <meta
            property="twitter:image"
            content={`${process.env.REACT_APP_S3_BUCKET}/${wallpaper.thumbnail}`}
          />
        )}
      </Helmet>
    );
  };

  return (
    <React.Fragment>
      {wallpaper && seoContent()}
      <div className="container-fluid p-0" style={{ overflowWrap: "anywhere" }}>
        <div
          className="modal-inner-body"
          style={{ minWidth: mediaReady ? 0 : "calc(100vw - 300px)" }}
        >
          <LightboxMedia />
          <div className="wallpaper-description">
            <LightboxDetails />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LightboxContent;
