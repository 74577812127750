import React from "react";

const UnderDevelopment = ({ title = "Under Development" }) => {
  return (
    <React.Fragment>
      {/* <div style={{ width: "50vh" }}>
        <img
          width='100px'
          src='/images/Illustrations/under_construction.svg'
          className='w-100 h-100'
          alt=''
        />
      </div> */}

      <div className='d-flex flex-column justify-content-center align-items-center m-5'>
        <h1 className='mb-5 font-weight-bold'>{title}</h1>
        <img
          src='/images/Illustrations/under_construction.svg'
          className='w-100'
          alt=''
        />
      </div>
    </React.Fragment>
  );
};

export default UnderDevelopment;
