import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadCommentsByWallpaperId } from "../../actions/comment";
import SingleComment from "./SingleComment";

const Comments = () => {
  const { currentWallpaper: wallpaper, comments } = useSelector(
    (state) => state.lightbox
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCommentsByWallpaperId(wallpaper._id, 0));
  }, [wallpaper]);

  const getComments = async () => {
    dispatch(loadCommentsByWallpaperId(wallpaper._id, comments.data.length));
  };

  return (
    <div>
      {comments && comments.data && (
        <React.Fragment>
          {comments.data.map((c, i) => {
            return <SingleComment comment={c} key={i} />;
          })}

          <div className="mt-4 d-flex justify-content-center">
            <button
              className={`btn btn-secondary ${!comments.hasMore && "d-none"}`}
              // disabled={!comments.hasMore}
              onClick={getComments}
            >
              Load More
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Comments;
