import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";
import LightboxContent from "./LightboxContent";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleLightbox,
  closeLightbox,
  setCurrentIndex,
} from "../../actions/lightbox";

const Lightbox = () => {
  const { isOpen, currentIndex } = useSelector((state) => state.lightbox);
  const data = useSelector((state) => state.gallery.data);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.onkeydown = checkKey;
    }
  }, [currentIndex]);

  const increaseCurrentIndex = () =>
    dispatch(setCurrentIndex(currentIndex + 1));
  const decreaseCurrentIndex = () =>
    dispatch(setCurrentIndex(currentIndex - 1));

  const checkKey = (e) => {
    e = e || window.event;
    if (e.keyCode == 37) {
      // left arrow
      if (isOpen && currentIndex > 0) {
        decreaseCurrentIndex();
      }
    } else if (e.keyCode == 39) {
      // right arrow
      if (isOpen && currentIndex < data.length - 1) {
        increaseCurrentIndex();
      }
    }
  };

  const modalContent = () => {
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          // fade={fade}
          centered
          fullscreen=""
          size="xl"
          toggle={() => dispatch(toggleLightbox())}
          onTransitionEnd={() => {
            //  setFade(false);
          }}
          external={
            <React.Fragment>
              <button
                className={`gallery-nav gallery-nav-arrow py-2 ${
                  currentIndex <= 0 && "disabled"
                }`}
                onClick={() => {
                  decreaseCurrentIndex();
                }}
                style={{
                  position: "absolute",
                  left: "30px",
                  // top: "calc(50vh - 30px)",
                }}
                disabled={currentIndex <= 0}
              >
                <FiChevronLeft size={40} />
              </button>
              <button
                className={`gallery-nav gallery-nav-arrow py-2 ${
                  currentIndex >= data.length - 1 && "disabled"
                }`}
                onClick={() => {
                  increaseCurrentIndex();
                }}
                style={{
                  position: "absolute",
                  right: "30px",
                  // top: "calc(50vh - 30px)",
                }}
                disabled={currentIndex >= data.length - 1}
              >
                <FiChevronRight size={40} />
              </button>
              {/* X */}
              <button
                className={`gallery-nav p-2 ${currentIndex <= 0 && "disabled"}`}
                onClick={() => dispatch(closeLightbox())}
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "20px",
                }}
              >
                <FiX size={25} />
              </button>
            </React.Fragment>
          }
        >
          <ModalBody>
            {currentIndex !== null && currentIndex !== undefined ? (
              <LightboxContent wallpaperId={data[currentIndex]._id} />
            ) : (
              ""
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  };

  // if (!currentIndex) return null;

  return <div>{data && data.length > 0 && modalContent()}</div>;
};

export default Lightbox;
