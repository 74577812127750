export const setActiveTab = (index) => async (dispatch) => {
  return dispatch({
    type: "SET_ACTIVE_TAB",
    payload: {
      index,
    },
  });
};

export const setTimeframe = (timeframe) => async (dispatch) => {
  return dispatch({
    type: "SET_TIMEFRAME",
    payload: {
      timeframe,
    },
  });
};

export const setGalleryData = (data) => async (dispatch) => {
  return dispatch({
    type: "SET_GALLERY_DATA",
    payload: {
      data,
    },
  });
};

export const addGalleryData = (data) => async (dispatch) => {
  return dispatch({
    type: "ADD_GALLERY_DATA",
    payload: {
      data,
    },
  });
};
