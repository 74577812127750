import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { Alert } from "reactstrap";
import { isAuth } from "../../services/api/auth";

const NotConfirmedAlert = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);

  const close = () => setIsOpen(false);

  return (
    <div>
      {location.pathname !== "/confirm" && isAuth() && !isAuth().confirmed && (
        <div className="mt-2 mb-5">
          <Alert
            color="warning"
            className="d-flex justify-content-between"
            isOpen={isOpen}
          >
            <span className="my-auto">Please confirm your email</span>
            <div className="d-inline-flex">
              <Link to={`/confirm`}>
                <button className="btn btn-warning btn-sm">
                  Go To Confirmation Page
                </button>
              </Link>

              {/* <button
                className="btn btn-warning btn-sm ml-2"
                onClick={() => close()}
              >
                Remind Me Later
              </button> */}
            </div>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default NotConfirmedAlert;
