export const getCategoryBySlug = (slug, list) => {
  if (list && list.length) {
    const matchingCategoryIndex = list.findIndex((x) => {
      return x.slug === slug;
    });

    return list[matchingCategoryIndex];
  } else {
    return null;
  }
};

export const getCategoryById = (id, list) => {
  if (list && list.length) {
    const matchingCategoryIndex = list.findIndex((x) => {
      return x.value === id;
    });

    return list[matchingCategoryIndex];
  } else {
    return null;
  }
};
