import React from "react";
import { FaApple, FaFacebookF } from "react-icons/fa";
import { FormGroup } from "reactstrap";
import LoginGoogle from "./LoginGoogle";

const SocialLogin = () => {
  return (
    <div className="text-left">
      <FormGroup className="mt-3">
        <LoginGoogle />
      </FormGroup>
      {/* <FormGroup className="mt-3">
        <a
          href=""
          style={{ height: "60px", padding: "2px" }}
          className="btn btn-primary btn-block br-2 overflow-hidden"
        >
          <span className="btn-light br-2 d-flex align-items-center h-100 px-3">
            <FaFacebookF size="1.5em" className="ml-1 mr-3" /> Signin with
            Facebook
          </span>
        </a>
      </FormGroup> */}
      {/* <FormGroup className="mt-3">
        <a
          href=""
          style={{ height: "60px", padding: "2px" }}
          className="btn btn-primary btn-block br-2 overflow-hidden"
        >
          <span className="btn-light br-2 d-flex align-items-center h-100 px-3">
            <FaApple size="1.5em" className="ml-1 mr-3" /> Signin with Apple
            Account
          </span>
        </a>
      </FormGroup> */}
    </div>
  );
};

export default SocialLogin;
