import React, { useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Notice from "../Notice";

const CustomScrollbar = ({
  children,
  page = false,
  fullWidth = true,
  setScrollToTop,
  className,
}) => {
  const scrollBarRef = useRef();

  useEffect(() => {
    if (setScrollToTop) {
      function theFunctionToCall() {
        scrollBarRef.current.scrollToTop(0);
      }
      setScrollToTop(() => theFunctionToCall);
    }
  }, [setScrollToTop]);

  return (
    <React.Fragment>
      <Scrollbars
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        // thumbMinSize={6}
        universal
        // className="scrollbar"
        className={`flex-1 ${className && className}`}
        ref={scrollBarRef}
        renderView={(props) => (
          <div {...props} className="view" id="custom-scrollbar" />
        )}
      >
        {page ? (
          fullWidth ? (
            <div className="container-fluid px-sm-5 px-3 py-4">
              {" "}
              <Notice />
              {children}
            </div>
          ) : (
            <div
              className="container-fluid px-sm-5 px-3 py-4"
              style={{ maxWidth: "1200px" }}
            >
              <Notice />
              {children}
            </div>
          )
        ) : (
          <React.Fragment>{children}</React.Fragment>
        )}
      </Scrollbars>
    </React.Fragment>
  );
};

export default CustomScrollbar;
