import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ThemeHandler = () => {
  const location = useLocation();
  const darkMode = useSelector((state) => state.config.darkMode);
  const userType = useSelector((state) => state.user.type);

  // Update Theme
  useEffect(() => {
    const className = "dark-mode";
    const element = window.document.body;
    if (darkMode && userType && userType === "pro") {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }, [darkMode, userType, location]);

  return null;
};

export default ThemeHandler;
