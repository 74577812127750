import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import EditRender from "./Edit";
import MainRender from "./Main";

const LightboxDetails = () => {
  const { currentWallpaper: wallpaper, editing } = useSelector(
    (state) => state.lightbox
  );

  if (!wallpaper) return null;

  return (
    <React.Fragment>
      <div className="d-flex flex-column w-100 h-100">
        {editing ? <EditRender /> : <MainRender />}
      </div>
    </React.Fragment>
  );
};

export default LightboxDetails;
