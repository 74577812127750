import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const createComment = (data, token) => {
  return fetch(`${API}/comment`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateComment = (commentId, data, token) => {
  return fetch(`${API}/comment/${commentId}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeComment = (commentId, token) => {
  return fetch(`${API}/comment/${commentId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const retrieveCommentsByWallpaperId = (wallpaperId, offset, token) => {
  let queryURL = `${API}/comment/wallpaper/${wallpaperId}`;

  if (offset) {
    queryURL = queryURL + `?offset=${offset}`;
  }

  return fetch(queryURL, {
    method: "GET",
    headers: token
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const voteComment = (commentId, token) => {
  return fetch(`${API}/comment/${commentId}/vote`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
