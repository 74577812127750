import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Router from "next/router";
// import NProgress from "nprogress";
// import "../../node_modules/nprogress/nprogress.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import {
  FiActivity,
  FiUser,
  FiBookmark,
  FiDollarSign,
  FiSettings,
  FiHelpCircle,
  FiLogOut,
  FiMenu,
  FiPlusSquare,
} from "react-icons/fi";

import { RiAdvertisementFill } from "react-icons/ri";
import { signout, isAuth } from "../../services/api/auth";

import ToggleTheme from "../Misc/ToggleTheme";

import MediaQuery from "react-responsive";
import SearchBar from "../Inputs/SearchBar";
import NotificationButton from "../Notification/NotificationButton";
import Avatar from "../Avatar";
import CreateButton from "../Buttons/CreateButton";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../actions/user";
// import NotificationDropdown from "../Notification/dropdown";

// Router.onRouteChangeStart = (url) => NProgress.start();
// Router.onRouteChangeComplete = (url) => NProgress.done();
// Router.onRouteChangeError = (url) => NProgress.done();

const NavBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const profileDropDown = () => {
    return (
      <UncontrolledDropdown nav inNavbar tag="span">
        <DropdownToggle nav caret={false} tag="span">
          <Avatar
            onClick={(e) => console.log("Open Menu")}
            width="auto"
            height="35px"
            size="sm"
            self
          />
        </DropdownToggle>
        <DropdownMenu right>
          <Link to={`/profile/${isAuth().username}`}>
            <DropdownItem
            // tag={"a"}
            //  onClick={() => Router.push(`/profile/${isAuth().username}`)}
            >
              <FiUser className="mr-3" color="#7d7d7d" size={20} />
              Profile
            </DropdownItem>
          </Link>

          {/* <Link to={`/bookmarks`}>
           
              <DropdownItem>
                <FiBookmark className="mr-3" color="#7d7d7d" size={20} />
                Bookmarks
              </DropdownItem>
            
          </Link> */}

          <DropdownItem divider />

          {/* <Link to={`/advertise`}>
            
              <DropdownItem>
                <RiAdvertisementFill
                  className="mr-3"
                  color="#7d7d7d"
                  size={20}
                />
                Advertise
              </DropdownItem>
          
          </Link> */}
          {/* 
          <DropdownItem>
            <FiDollarSign className="mr-3" color="#7d7d7d" size={20} />
            Payments
          </DropdownItem> */}
          {/* <DropdownItem divider /> */}

          <Link to={`/settings`}>
            <DropdownItem>
              <FiSettings className="mr-3" color="#7d7d7d" size={20} />
              Settings
            </DropdownItem>
          </Link>

          <Link to={`/`}>
            <DropdownItem>
              <FiHelpCircle className="mr-3" color="#7d7d7d" size={20} />
              Help
            </DropdownItem>
          </Link>

          <DropdownItem divider />

          <DropdownItem
            onClick={() =>
              signout(() => {
                dispatch(clearUserData()).then(() => {
                  navigate("/", { replace: true });
                });
              })
            }
            className="text-danger"
          >
            <FiLogOut className="mr-3" color="" size={20} /> Signout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const authorizedButtons = () => {
    return (
      <React.Fragment>
        <div className="d-inline-flex">
          <CreateButton />

          {/* <a href="/pro">Get Pro</a> */}
        </div>
      </React.Fragment>
    );
  };

  const unauthorizedButtons = () => {
    return (
      <div className="ml-auto">
        <div className="d-flex">
          <Button
            className="br-2 mr-3 my-auto"
            color="primary"
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
          <Button
            className="br-2 mr-3 my-auto"
            color="primary"
            onClick={() => navigate("/signup")}
          >
            Signup
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Navbar
        expand="md"
        style={{ height: "80px" }}
        // className="d-flex flex-row w-100"
        className="justify-content-between"
      >
        <div className="d-inline-flex align-items-center">
          <MediaQuery maxWidth={1024}>
            <a onClick={() => props.toggleSidebarOpen()} className="mr-3 p-2">
              <FiMenu size={24} color="#7d7d7d" />
            </a>

            <Link to="/">
              <img
                className="mr-3"
                src="/images/logo.png"
                alt="Logo"
                style={{ height: "50px" }}
              />
            </Link>
          </MediaQuery>
          <Collapse isOpen={isOpen} navbar className="">
            <SearchBar />
            {/* {isAuth() ? authorizedContent() : unauthorizedContent()} */}
          </Collapse>
        </div>

        <div className="d-inline-flex align-items-center">
          {isAuth() ? authorizedButtons() : unauthorizedButtons()}

          <ToggleTheme />

          {isAuth() && (
            <React.Fragment>
              <div
                className="my-auto"
                style={{
                  borderLeft: "1px solid #7d7d7d50",
                  margin: "10px",
                  height: "25px",
                }}
              ></div>
              <NotificationButton />
              {profileDropDown()}
            </React.Fragment>
          )}
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default NavBar;
