import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "react-dropzone/examples/theme.css";
import { BiUpload } from "react-icons/bi";

// const img = {
//   display: "block",
//   width: "100%",
//   height: "auto",
//   position: "relative",
//   top: "50%",
//   transform: "translateY(-50%)",
//   filter: "brightness(25%)",
// };

const DropzoneComponent = ({ handleFiles }) => {
  //   const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      handleFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <div className="d-flex flex-column text-center m-auto">
        <h2 className="display-5">Drag and drop image files</h2>
        <p className="text-muted">Share something special with everyone</p>

        <button
          className="btn btn-primary br-2 m-auto px-4 d-flex"
          style={{
            height: "45px",
          }}
        >
          <BiUpload className="my-auto mr-1" color="#ffffff" size={20} />{" "}
          <span className="my-auto font-weight-bold">Or choose files</span>
        </button>
      </div>
    </div>
  );
};
export default DropzoneComponent;
