import React from "react";
import Select from "react-select";

const SelectOption = ({ label, options, onSelect, value }) => {
  return (
    <div>
      <label>{label}</label>
      <Select
        value={value}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={(e) => onSelect(e)}
        aria-sort="ascending"
      />
    </div>
  );
};

export default SelectOption;
