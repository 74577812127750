import { getCookie } from "../services/api/auth";
import {
  updateWallpaper,
  retrieveWallpaper,
  removeWallpaper,
} from "../services/api/wallpaper";
import { decreaseWallpapersCount } from "./user";

export const openLightbox = () => (dispatch) =>
  dispatch({
    type: "OPEN_LIGHTBOX",
  });
export const closeLightbox = () => (dispatch) =>
  dispatch({
    type: "CLOSE_LIGHTBOX",
  });
export const toggleLightbox = () => (dispatch) =>
  dispatch({
    type: "TOGGLE_LIGHTBOX",
  });

export const setEditing = (value) => async (dispatch) => {
  return dispatch({
    type: "SET_EDITING_LIGHTBOX",
    payload: {
      value,
    },
  });
};

export const setCurrentIndex = (index) => async (dispatch) => {
  return dispatch({
    type: "SET_CURRENT_INDEX_LIGHTBOX",
    payload: {
      index,
    },
  });
};

export const loadWallpaper = (wallpaperId) => async (dispatch) => {
  const token = getCookie("token");
  const res = await retrieveWallpaper(wallpaperId, token).then((data) => {
    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  let finalData = res;

  return dispatch({
    type: "SET_WALLPAPER_LIGHTBOX",
    payload: {
      data: finalData,
    },
  });
};

export const editWallpaper = (wallpaperId, data) => async (dispatch) => {
  const token = getCookie("token");
  const res = await updateWallpaper(wallpaperId, data, token).then((data) => {
    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  let finalData = res;

  // Stop Editing
  dispatch({
    type: "SET_EDITING_LIGHTBOX",
    payload: {
      value: false,
    },
  });

  // update Wallpaper
  dispatch({
    type: "SET_WALLPAPER_LIGHTBOX",
    payload: {
      data: finalData,
    },
  });
  // Remove above - change only Desc, Tags, Category,

  // update this wallpaper in all dataset
  // hot
  // latest
  // timeline
  // categories latest
  // categories hot

  return;
};

export const deleteWallpaper = (wallpaperId) => async (dispatch) => {
  let answer = window.confirm(
    "Are you sure you want to delete this wallpaper?"
  );
  if (answer) {
    const token = getCookie("token");
    const res = await removeWallpaper(wallpaperId, token).then((data) => {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    });

    // removing from Current Gallery
    dispatch({
      type: "GALLERY_REMOVE_SINGLE_ITEM",
      payload: {
        wallpaperId,
      },
    });

    // remove this wallpaper in all dataset
    // hot
    // latest
    // timeline
    // categories latest
    // categories hot
    // dispatch({
    //   type: "CLOSE_LIGHTBOX",
    // });

    dispatch(decreaseWallpapersCount());
    dispatch(closeLightbox());

    return;
  }
};

export const setMediaReady = (value) => async (dispatch) => {
  return dispatch({
    type: "SET_MEDIA_READY_LIGHTBOX",
    payload: {
      value,
    },
  });
};

/* -------------------------------- COMMENTS -------------------------------- */
