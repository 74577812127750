import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description = "Wallpaper Sharing Social Network",
  image = "https://wallhippo.s3.filebase.com/misc/seo.jpg",
  hasDynamicContent = false,
}) => {
  const keywords = [
    "wallpaper",
    "hd",
    "social",
    "network",
    "windows",
    "mac",
    "linux",
    "android",
    "ios",
    "tablet",
    "mobile",
    "desktop",
    "laptop",
  ];

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      {/* <meta name="viewport" content="width-device-width, initial scale=1.0" /> */}
      <link rel="canonical" href={process.env.REACT_APP_APP_DOMAIN} />
      <meta name="robots" content="index, follow" />

      {/*  Primary Meta Tags  */}
      <title>
        {process.env.REACT_APP_APP_NAME}
        {title && " - " + title}
      </title>
      <meta
        name="title"
        content={`${process.env.REACT_APP_APP_NAME}${title && " - " + title}`}
      />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        // content="wallpaper, hd, social, network, windows, mac, linux, android, ios, tablet, mobile, desktop, laptop"
        content={keywords.join(", ")}
      />

      {/* Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.REACT_APP_APP_DOMAIN} />
      <meta
        property="og:title"
        content={`${process.env.REACT_APP_APP_NAME}${title && " - " + title}`}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={process.env.REACT_APP_APP_DOMAIN} />
      <meta
        property="twitter:title"
        content={`${process.env.REACT_APP_APP_NAME}${title && " - " + title}`}
      />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
};

export default SEO;
