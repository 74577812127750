const INITIAL_STATE = {
  data: [],
  unreadCount: 0,
  //   fetching: false,
  //   error: false,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION": {
      return {
        ...state,
        data: [action.payload, ...state.data],
        unreadCount: state.unreadCount + 1,
      };
    }
    case "FETCH_NOTIFICATIONS": {
      // const unreadCount = action.payload.filter(
      //   (notification) => notification.read === false
      // ).length;

      return {
        ...state,
        // fetching: false,
        // error: false,
        data: action.payload.data,
        unreadCount: action.payload.unreadCount
          ? action.payload.unreadCount
          : 0,
        count: action.payload.count,
        hasMore: action.payload.data.length < 20 ? false : true,
      };
    }

    case "FETCH_MORE_NOTIFICATIONS":
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        count: action.payload.count,
        hasMore:
          state.data.length + action.payload.data.length >=
            action.payload.count || action.payload.data.length < 20
            ? false
            : true,
      };

    case "READ_SINGLE_NOTIFICATION": {
      let newUnreadCount = Number(state.unreadCount);

      const updatedNotifications = state.data.map((n) => {
        if (n._id === action.payload.id && !n.read) {
          n.read = true;
          newUnreadCount--;
        }
        return n;
      });

      return {
        ...state,
        unreadCount: newUnreadCount,
        data: updatedNotifications,
      };
    }

    case "READ_ALL_NOTIFICATIONS": {
      return {
        ...state,
        unreadCount: 0,
        data: state.data.map((s) => {
          s.read = true;
          return s;
        }),
      };
    }
    case "CLEAR_NOTIFICATIONS": {
      return {
        ...state,
        unreadCount: 0,
        data: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default notificationReducer;
