import { getCookie } from "../services/api/auth";
import {
  createComment,
  updateComment,
  removeComment,
  retrieveCommentsByWallpaperId,
} from "../services/api/comment";

export const loadCommentsByWallpaperId =
  (wallpaperId, offset = 0) =>
  async (dispatch) => {
    const token = getCookie("token");
    await retrieveCommentsByWallpaperId(wallpaperId, offset, token).then(
      (data) => {
        if (data.error) {
          console.error(data.error);
        } else {
          // set or load

          if (offset === 0) {
            return dispatch({
              type: "SET_COMMENTS",
              payload: {
                data: data,
              },
            });
          } else {
            return dispatch({
              type: "LOAD_MORE_COMMENTS",
              payload: {
                data: data,
              },
            });
          }
        }
      }
    );
  };

export const addComment = (commentData) => async (dispatch) => {
  const token = getCookie("token");

  const res = await createComment(commentData, token).then((data) => {
    if (data.error) {
      throw data.error;
    } else {
      return data;
    }
  });

  let finalData = res;

  // update Comment
  dispatch({
    type: "CREATE_COMMENT",
    payload: {
      data: finalData,
    },
  });

  return;
};

export const editComment = (commentId, data) => async (dispatch) => {
  const token = getCookie("token");
  const res = await updateComment(commentId, { content: data }, token).then(
    (data) => {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    }
  );

  let finalData = res;

  // update Comment
  dispatch({
    type: "UPDATE_COMMENT",
    payload: {
      data: finalData,
    },
  });

  return;
};

export const deleteComment = (commentId) => async (dispatch) => {
  let answer = window.confirm("Are you sure you want to delete this comment?");
  if (answer) {
    const token = getCookie("token");
    const res = await removeComment(commentId, token).then((data) => {
      if (data.error) {
        throw data.error;
      } else {
        return data;
      }
    });

    // remove from comment array
    dispatch({
      type: "REMOVE_COMMENT",
      payload: {
        id: commentId,
      },
    });
  }
};
