import React from "react";
import { FiHeart, FiMessageCircle } from "react-icons/fi";

const CategoryCustomRenderer = ({
  index,
  left,
  top,
  key2,
  photo,
  margin,
  direction,
  hoho,
  onClick,
}) => {
  const cont = {};
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };

  return (
    <div
      key={index}
      className="category-container"
      style={{
        margin,
        height: photo.height,
        width: photo.width,
        ...cont,
      }}
      onClick={onClick ? handleClick : null}
    >
      {/* <img
        src={`${process.env.REACT_APP_S3_BUCKET}/${photo.src}`}
        height={photo.height}
        width={photo.width}
        alt="wallpaper"
      /> */}

      <div
        className="m-2 d-flex align-items-center justify-content-center"
        style={{
          height: photo.height,
          width: photo.width,
          backgroundImage:
            "url(" + `${process.env.REACT_APP_S3_BUCKET}/${photo.src}` + ")",
          backgroundPosition: "center",
          objectFit: "contain",
        }}
      >
        <span
          className=""
          style={{ fontSize: "1.75em", textShadow: "0px 0px 15px #00000050" }}
        >
          {photo.label}
        </span>
      </div>

      {/* <div
        className="overlay position-absolute "
        style={{
          height: photo.height,
          width: photo.width,
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: "4",
        }}
      >
        <div className="overlay-content" style={{}}>
          <div
            className="d-flex align-items-center justify-content-center"
            //   style={{
            //     height: "108px",
            //     width: "192px",
            //     backgroundColor: "#2d2d2d",
            //   }}
          >
            <span className="">{photo.label}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CategoryCustomRenderer;
