import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const retrieveWallpaper = (wallpaperId, token) => {
  return fetch(`${API}/wallpaper/${wallpaperId}`, {
    method: "GET",
    headers: token
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateWallpaper = (wallpaperId, data, token) => {
  return fetch(`${API}/wallpaper/${wallpaperId}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeWallpaper = (wallpaperId, token) => {
  return fetch(`${API}/wallpaper/${wallpaperId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const voteWallpaper = (wallpaperId, token) => {
  return fetch(`${API}/wallpaper/${wallpaperId}/vote`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const searchWallpapers = (queryString, offset = 0) => {
  return fetch(`${API}/wallpaper/search/${queryString}?offset=${offset}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

/* ------------------------------- Retrieve By ------------------------------ */
export const retrieveWallpapersByUser = (username, offset) => {
  let queryURL = `${API}/user/${username}/wallpapers`;
  if (offset) {
    queryURL = queryURL + `?offset=${offset}`;
  }

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

// export const searchWallpapers = (queryString, offset = 0) => {
//   return fetch(`${API}/wallpaper/search/${offset}?query=${queryString}`, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//   })
//     .then((response) => {
//       handleResponse(response);
//       return response.json();
//     })
//     .catch((err) => console.log(err));
// };
