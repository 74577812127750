import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addComment } from "../../actions/comment";
import { getCookie } from "../../services/api/auth";
import { createComment } from "../../services/api/comment";

const CreateComment = ({ wallpaperId }) => {
  const token = getCookie("token");
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    comment: "",
    error: "",
  });
  const { comment, error } = values;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!comment || comment.length < 1) {
      setValues({ ...values, error: "Comment is required" });
      return;
    }

    // // send Request
    // dispatch(addComment(wallpaperId, { content: comment }, token)).then(() =>
    //   setValues({ ...values, comment: "" })
    // );

    // send Request
    dispatch(addComment({ content: comment, wallpaperId })).then(() =>
      setValues({ ...values, comment: "" })
    );
  };

  return (
    <form className="input-group" onSubmit={handleSubmit}>
      <input
        type="text"
        className="form-control"
        placeholder="Add a comment"
        aria-label="Add a comment"
        aria-describedby="basic-addon2"
        value={comment}
        onChange={(e) => setValues({ ...values, comment: e.target.value })}
        maxLength={200}
      />
      <div className="input-group-append">
        <button className="btn btn-primary" type="submit">
          Post
        </button>
      </div>
    </form>
  );
};

export default CreateComment;
