import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";
import NotificationList from "../components/Notification/NotificationList";
import { fetchNotifications } from "../actions/notification";
import SEO from "../components/Misc/SEO";

const Notifications = () => {
  const {
    data: notifications,
    unreadCount,
    count,
    hasMore,
  } = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  const getNotifications = async () => {
    dispatch(fetchNotifications(notifications.length)); // offset
  };

  return (
    <Layout>
      <SEO title="Notifications" />
      <Private>
        <div className="inner-content w-100 ">
          <CustomScrollbar page={true}>
            <h1>Notifications</h1>
            <hr />
            <ul className="p-0" style={{ listStyle: "none" }}>
              <NotificationList />
            </ul>
            <div className="mt-4 d-flex justify-content-center">
              <button
                className={`btn btn-secondary ${!hasMore && "d-none"}`}
                // disabled={!comments.hasMore}
                onClick={getNotifications}
              >
                Load More
              </button>
            </div>
          </CustomScrollbar>
        </div>
      </Private>
    </Layout>
  );
};

export default Notifications;
