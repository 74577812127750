import React from "react";
import { animated } from "react-spring";

import { FiUser, FiHeart, FiMessageCircle } from "react-icons/fi";

const NotificationPopup = ({ style, notifications }) => {
  let newFollowers = 0;
  let newLikes = 0;
  let newComments = 0;

  notifications.forEach((notification) => {
    if (!notification.read) {
      switch (notification.type) {
        case "follow": {
          newFollowers += 1;
          break;
        }
        case "comment":
        case "comment-reply":
        case "mention": {
          newComments += 1;
          break;
        }
        default: {
          newLikes += 1;
        }
      }
    }
  });

  const renderIcon = (icon, number) => (
    <div>
      {icon === "follower" ? (
        <FiUser className="my-auto" size={20} color="#fff" fill="#fff" />
      ) : icon === "like" ? (
        <FiHeart className="my-auto" size={20} color="#fff" fill="#fff" />
      ) : icon === "comment" ? (
        <FiMessageCircle
          className="my-auto"
          size={20}
          color="#fff"
          fill="#fff"
        />
      ) : null}
      <span>{number}</span>
    </div>
  );

  return (
    <animated.div className="notification-button__popup" style={style}>
      {newFollowers > 0 && renderIcon("follower", newFollowers)}
      {newLikes > 0 && renderIcon("like", newLikes)}
      {newComments > 0 && renderIcon("comment", newComments)}
    </animated.div>
  );
};

export default NotificationPopup;
