import React, { useState, useEffect } from "react";
import { getCookie } from "../../services/api/auth";
import ImageGallery from "../ImageGallery";
import { setGalleryData } from "../../actions/gallery";
import { useDispatch } from "react-redux";
import { searchWallpapers } from "../../services/api/wallpaper";
import { Alert } from "reactstrap";

const SearchGallery = ({ queryString }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    count: 0,
    error: "",
  });
  const { count, error } = values;

  useEffect(() => {
    // get sample wallpaper data
    getSearchedWallpapers();
  }, [queryString]);

  const getSearchedWallpapers = async () => {
    await searchWallpapers(queryString, 0).then((data) => {
      if (data.error) {
        setValues({
          ...values,
          error: data.error,
        });
      } else if (data) {
        setValues({
          ...values,
          count: data.wallpaperCount,
          error: "",
        });
        dispatch(setGalleryData(data.wallpapers));
      } else {
        setValues({ ...values, count: 0, error: "" });
        dispatch(setGalleryData([]));
      }
    });
  };

  const showError = () => {
    return error && <Alert color="danger">{error}</Alert>;
  };

  return (
    <div>
      {showError()}
      <h5 className="ml-3 display-5">{count} Wallpaper Results</h5>
      <ImageGallery />
    </div>
  );
};

export default SearchGallery;
