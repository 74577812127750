import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const readNotification = (id, token) => {
  return fetch(`${API}/notification/read/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    // body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const retrieveNotifications = (offset, token) => {
  let queryURL = `${API}/notification`;

  if (offset) {
    queryURL = queryURL + `?offset=${offset}`;
  }

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    //   body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const readUserNotifications = (token) => {
  return fetch(`${API}/notification/read/all`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    // body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};
