import React from "react";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";

import SettingsComponent from "../components/Settings";
import SEO from "../components/Misc/SEO";

const Settings = () => {
  return (
    <Layout>
      <SEO title="Settings" />
      <Private>
        <CustomScrollbar page={true} fullWidth={false}>
          <h2 className="" style={{}}>
            Account Settings
          </h2>
          <SettingsComponent />
        </CustomScrollbar>
      </Private>
    </Layout>
  );
};

export default Settings;
