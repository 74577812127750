import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Alert } from "reactstrap";
import { isAuth } from "../../services/api/auth";

const NoUsernameAlert = () => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => setIsOpen(false);

  return (
    <div>
      {isAuth() && !isAuth().username && (
        <div className="mt-2 mb-5">
          <Alert
            color="warning"
            className="d-flex justify-content-between"
            isOpen={isOpen}
          >
            <span className="my-auto">Please create your username</span>
            <div className="d-inline-flex">
              <Link to={`/settings`}>
                <button className="btn btn-warning btn-sm">
                  Go To Settings
                </button>
              </Link>

              {/* <button
                className="btn btn-warning btn-sm ml-2"
                onClick={() => close()}
              >
                Remind Me Later
              </button> */}
            </div>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default NoUsernameAlert;
