import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { Media } from "reactstrap";
// import moment from "moment";
// import LikeButton from "../../../../../Buttons/LikeButton";
import { getCookie } from "../../services/api/auth";

// import {
//   FiMoreVertical,
//   FiEdit,
//   FiShare2,
//   FiTrash2,
//   FiFlag,
//   FiCheck,
//   FiX,
// } from "react-icons/fi";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { createCommentReply } from "../../services/api/commentReply";
// import { deleteComment, editComment } from "../../../../../../actions/comment";
// import Avatar from "../../../../../Avatar";
// import { openReportModal } from "../../../../../../actions/report";
// import { closeLightbox } from "../../../../../../actions/lightbox";

const CreateCommentReply = ({ commentId, createOpenReplyCall, replyingTo }) => {
  const token = getCookie("token");
  const [values, setValues] = useState({
    content: "",
    error: "",
    loading: false,
  });
  const { content, error, loading } = values;

  const [isOpen, setIsOpen] = useState(false);
  const closeInput = () => {
    setIsOpen(false);
    setValues({
      content: "",
      error: "",
      loading: false,
    });
  };

  useEffect(() => {
    function theFunctionToCall() {
      // do something like setting something
      // don't forget to set dependancies properly.
      // alert("Open not open");
      setIsOpen(true);
    }
    createOpenReplyCall(() => theFunctionToCall);
  }, [createOpenReplyCall]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });

    createCommentReply(
      {
        content: replyingTo ? `${"@" + replyingTo + " "}${content}` : content,
        commentId,
      },
      token
    ).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
        return;
      }

      setValues({ ...values, content: "", error: "", loading: false });
      setIsOpen(false);
    });
  };

  const newLine = (e) => {
    if (e.keyCode == 13 && e.shiftKey) {
      // alert("Shift+Enter key Pressed");
    } else if (e.keyCode == 13) {
      // alert(e.target.value);
      handleSubmit(e);
    }
  };

  return isOpen ? (
    <React.Fragment>
      <FormGroup
        className="mt-1 mb-0"
        // className="mt-2 mb-4"
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   alert("hehe");
        // }}
      >
        {replyingTo && (
          <p
            className="bg-secondary text-white px-2"
            style={{
              borderRadius: "6px 6px 0px 0px",
            }}
          >
            @{replyingTo}
          </p>
        )}

        <Input
          type="textarea"
          // type="text"
          name="reply"
          id="exampleEmail"
          placeholder="Enter your reply"
          onKeyDown={newLine}
          value={content}
          onChange={(e) => setValues({ ...values, content: e.target.value })}
          maxLength={replyingTo ? 200 - replyingTo.length + 2 : 200}
        />
        <div className="text-right">
          <a onClick={() => closeInput()}>Cancel</a>
        </div>
      </FormGroup>
    </React.Fragment>
  ) : null;
};

export default CreateCommentReply;
