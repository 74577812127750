import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ImageGallery from "../ImageGallery";
import {
  loadHotFeed,
  loadLatestFeed,
  loadTimelineFeed,
} from "../../actions/feed";
import { setGalleryData } from "../../actions/gallery";

import { TabContent, TabPane } from "reactstrap";
import LazyScroll from "../Misc/LazyScroll";

const FeedGallery = ({}) => {
  const { hot, latest, timeline } = useSelector((state) => state.feed);
  // const feedData = useSelector((state) => state.feed);
  const { activeTab, timeframe } = useSelector((state) => state.gallery);

  const dispatch = useDispatch();

  useEffect(() => {
    initFeed(activeTab, timeframe);
    cloneSetGalleryData();
  }, [activeTab, timeframe]);

  const initFeed = (tab, timeframe) => {
    switch (tab) {
      case "hot":
        if (!hot[timeframe].data || !hot[timeframe].data.length) {
          console.log("Loading Hot");
          dispatch(loadHotFeed(0, timeframe));
        }
        break;
      case "latest":
        if (!latest.data || !latest.data.length) {
          console.log("Loading Latest");
          dispatch(loadLatestFeed(0));
        }
        break;
      case "timeline":
        if (!timeline.data || !timeline.data.length) {
          console.log("Loading Timeline");
          dispatch(loadTimelineFeed(0));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    cloneSetGalleryData();
  }, [hot, latest, timeline]);

  const cloneSetGalleryData = () => {
    switch (activeTab) {
      case "hot":
        dispatch(setGalleryData(hot[timeframe].data));
        break;
      case "latest":
        dispatch(setGalleryData(latest.data));
        break;
      case "timeline":
        dispatch(setGalleryData(timeline.data));
        break;
      default:
        break;
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "hot":
        return (
          <LazyScroll
            dataLength={hot[timeframe].data.length}
            next={() => {
              if (activeTab === "hot")
                dispatch(loadHotFeed(hot[timeframe].data.length));
            }}
            hasMore={hot[timeframe].hasMore}
          >
            <ImageGallery />
          </LazyScroll>
        );
      case "latest":
        return (
          <LazyScroll
            dataLength={latest.data.length}
            next={() => {
              console.log("Loading New Data");
              if (activeTab === "latest")
                dispatch(loadLatestFeed(latest.data.length));
            }}
            hasMore={latest.hasMore}
          >
            <ImageGallery />
          </LazyScroll>
        );
      case "timeline":
        return (
          <LazyScroll
            dataLength={timeline.data.length}
            next={() => {
              console.log("Loading New Data");
              if (activeTab === "timeline")
                dispatch(loadTimelineFeed(timeline.data.length));
            }}
            hasMore={timeline.hasMore}
          >
            <ImageGallery />
          </LazyScroll>
        );
      default:
        return null;
    }
  };

  return <React.Fragment>{renderActiveTab()}</React.Fragment>;
};

export default FeedGallery;
