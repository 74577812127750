import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
} from "reactstrap";
import { FaEye, FaEyeSlash, FaArrowRight } from "react-icons/fa";
import Header from "../Header";

import { loginUser, authenticate } from "../../services/api/auth";
import LoginGoogle from "../Auth/LoginGoogle";
import SocialLogin from "../Auth/SocialLogin";
import { useDispatch } from "react-redux";
import { initUserData } from "../../actions/user";

const LoginComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    usernameOrEmail: "",
    password: "",
    localErrors: {
      usernameOrEmail: "",
      password: "",
    },
    error: "",
    loading: false,
  });
  const { usernameOrEmail, password, localErrors, error, loading } = values;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordVisible(passwordVisible ? false : true);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
      localErrors: {
        usernameOrEmail: "",
        password: "",
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate
    if (!usernameOrEmail) {
      setValues({
        ...values,
        localErrors: {
          ...localErrors,
          usernameOrEmail: "Email or Username is Required",
        },
      });
      return;
    } else if (!password) {
      setValues({
        ...values,
        localErrors: { ...localErrors, password: "Password is Required" },
      });
      return;
    } else {
      setValues({ ...values, loading: true });
      // wallpaper to backend
      const user = { usernameOrEmail, password };
      loginUser(user).then((data) => {
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
            loading: false,
          });
        } else {
          // save user token to cookie
          // save user info to localstorage
          // authenticate user
          authenticate(data, () => {
            if (data && data.user && data.user.username) {
              dispatch(initUserData(data.user.username)).then(() => {
                navigate(`/`);
              });
            }
          });
        }
      });
    }
  };

  const formContent = () => {
    return (
      <React.Fragment>
        {loading ? (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="text-left">
            {error && <div className="mt-3 alert alert-danger">{error}</div>}

            <Form onSubmit={handleSubmit}>
              <FormGroup className="mt-3 br-2">
                <Input
                  className="input-big"
                  placeholder="Email or Username"
                  type="text"
                  value={usernameOrEmail}
                  onChange={(e) =>
                    handleChange("usernameOrEmail", e.target.value)
                  }
                  invalid={localErrors.usernameOrEmail ? true : false}
                />

                {localErrors.usernameOrEmail && (
                  <FormFeedback> {localErrors.usernameOrEmail}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup className="mt-3 br-2 pass-wrapper">
                <InputGroup>
                  <Input
                    className="input-big"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    invalid={localErrors.password ? true : false}
                    maxLength={32}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        onClick={togglePasswordVisiblity}
                        className="mr-3 d-flex"
                      >
                        {passwordVisible ? (
                          <FaEyeSlash size={24} />
                        ) : (
                          <FaEye size={24} />
                        )}
                      </i>
                    </InputGroupText>
                  </InputGroupAddon>
                  {localErrors.password && (
                    <FormFeedback> {localErrors.password}</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>

              <button
                style={{ height: "60px" }}
                className="btn btn-block btn-primary br-2 mt-3 d-flex align-items-center"
              >
                <h5 className="mb-0 mr-auto">Login</h5>
                <FaArrowRight size={24} />
              </button>
            </Form>
          </div>
        )}
      </React.Fragment>
    );
  };

  const pageContent = () => {
    return (
      <div className="h-100">
        <div
          className="container my-3 text-center d-flex flex-column justify-content-around h-100"
          // style={{ minHeight: "700px" }}
          style={{ minHeight: "75vh" }}
        >
          <div className="px-5">
            <h1 className="">Login On {process.env.REACT_APP_APP_NAME}</h1>
            <h3 className="mt-3 text-muted">
              Welcome back to the Wallpaper Social Network <br /> Share
              something awesome with us
            </h3>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-lg-5 col-xl-5">{formContent()}</div>
            <div className="col-0 col-lg-1 col-xl-1 align-self-center slash"></div>
            <div className="col-12 col-lg-5 col-xl-5">
              <SocialLogin />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Header buttonType="Signup" /> {pageContent()}
    </React.Fragment>
  );
};

export default LoginComponent;
