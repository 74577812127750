import React, { useState, useEffect } from "react";
import { isAuth } from "../../services/api/auth";
import { useSelector, useDispatch } from "react-redux";

const Avatar = ({
  src,
  className,
  onClick,
  width,
  height,
  size,
  opacity,
  self,
}) => {
  const [avatarURL, setAvatarURL] = useState("");
  const [trial, setTrial] = useState(0);
  // const [mediaReady, setMediaReady] = useState(false);
  var { avatar, updatedAt } = useSelector((state) => state.user);

  const getImage = () => {
    let image;
    if (src) {
      image = src;
    } else if (self) {
      if (avatar) {
        image = avatar;
      } else {
        image = isAuth() && isAuth().avatar;
      }
    }
    // if (!image && size !== "sm") image = "/images/profile.jpg"; // SM will run getSmAvatar function
    return image;
  };

  const getSmAvatar = (key) => {
    if (key) {
      const keySM =
        key.slice(0, key.lastIndexOf(".")) +
        "-sm" +
        key.slice(key.lastIndexOf("."));

      return keySM;
    }
  };

  useEffect(() => {
    let imageSource = getImage();

    if (imageSource && size === "sm") {
      imageSource = getSmAvatar(imageSource);
    }

    if (!imageSource) {
      setAvatarURL("/images/profile.jpg");
    } else {
      setAvatarURL(`${process.env.REACT_APP_S3_BUCKET}/${imageSource}`);
    }
  }, [src, avatar]);

  const errorHandler = (e) => {
    if (size === "sm" && trial < 1) {
      // trial++;
      setTrial(trial + 1);

      setAvatarURL(`${process.env.REACT_APP_S3_BUCKET}/${getImage()}`);
    } else {
      e.target.src = "/images/profile.jpg";
    }
  };

  return (
    <React.Fragment>
      <div style={{ opacity: opacity, cursor: onClick ? "pointer" : "auto" }}>
        <img
          className={`profile-pic ${className ? className : ""}`}
          onClick={onClick && onClick}
          width={width}
          height={height}
          src={avatarURL + `?${updatedAt}`}
          key={avatarURL}
          alt="Avatar"
          onError={errorHandler}
          onLoad={() => setTrial(0)}
        />
      </div>
    </React.Fragment>
  );
};

export default Avatar;
