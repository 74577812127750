import React from "react";
import Layout from "../components/Layout";
import CustomScrollbar from "../components/Misc/CustomScrollbar";
import Private from "../components/Auth/Private";
import UnderDevelopment from "../components/Misc/UnderDevelopment";
import SEO from "../components/Misc/SEO";

const stats = () => {
  return (
    <Layout>
      <SEO title="Stats" />
      <Private>
        <CustomScrollbar page={true}>
          <UnderDevelopment />
        </CustomScrollbar>
      </Private>
    </Layout>
  );
};

export default stats;
