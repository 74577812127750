import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "reactstrap";

const LazyScroll = ({ dataLength, next, hasMore, children }) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loader={
        <div className="mt-4 d-flex justify-content-center align-items-center w-100 text-secondary">
          <Spinner
            style={{ width: "2em", height: "2em" }}
            type="grow"
            // color="light"
            className=""
          />{" "}
          <h4 className="ml-3 my-auto">Loading...</h4>
        </div>
      }
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      scrollableTarget="custom-scrollbar"
      scrollThreshold={0.7}
    >
      {children}
    </InfiniteScroll>
  );
};

export default LazyScroll;
