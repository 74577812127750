import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import queryString from "query-string";
import { isAuth, handleResponse } from "./auth";
const API = process.env.REACT_APP_API;

export const retrieveUser = (username, token) => {
  return fetch(`${API}/user/${username}`, {
    method: "GET",
    headers: token
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
    //   body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateUser = (data, token) => {
  return fetch(`${API}/user`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateUserAvatar = (data, token) => {
  return fetch(`${API}/user/avatar`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: token,
    },
    body: data,
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updatePassword = (data, token) => {
  return fetch(`${API}/user/password`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      handleResponse(response);
      return response.json();
    })
    .catch((err) => console.log(err));
};

/* ------------------------------- FOLLOW DATA ------------------------------ */

export const retrieveFollowers = (username, token, offset) => {
  let queryURL = `${API}/user/${username}/followers?${queryString.stringify({
    offset,
  })}`;

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const retrieveFollowing = (username, token, offset) => {
  let queryURL = `${API}/user/${username}/following?${queryString.stringify({
    offset,
  })}`;

  return fetch(queryURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
